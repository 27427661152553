import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { DefaultPolicyService } from "../../services/policy";
import { CancelablePromise } from "../../services/utilities/CancelablePromise";
import type { Policies } from "../../models/policy";

export const useGetPolicies = (portalKey: string) => {
	const policyService = new DefaultPolicyService({
		subdomain: "policyDocument",
	});
	return useQuery({
		queryKey: [portalKey],

		queryFn: () => {
			const cancelTokenSource = Axios.CancelToken.source();
			const getPolicies = new CancelablePromise<Policies>((resolve, reject) =>
				policyService
					.getPolicies(portalKey, cancelTokenSource)
					.then((r) => {
						resolve(r);
					})
					.catch((e) => {
						reject(e);
					}),
			);
			getPolicies.cancel = () => {
				cancelTokenSource.cancel();
			};
			return getPolicies;
		},
	});
};
