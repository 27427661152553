import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GeoPositionMap } from "../../GeoPosition/GeoPositionMap.component";
import { GetPosition } from "../../GeoPosition/GetCurrentPosition";
import { FormModal } from "../../modals";
import { ReactComponent as PositionIcon } from "../../../assets/svg/position.svg";
import { Button } from "../Button";
import { formatInput } from "./GeoPositionInput.container";
import type { MapStyle } from "../../../utilities/UseMapCopyrightInfo";
import type { ErrorType } from "../../GeoPosition/GetCurrentPosition";
import type { Position } from "../../GeoPosition/GeoPositionMap.component";

interface Props {
	showMapDialog: boolean;
	setShowMapDialog: (value: boolean) => void;
	lat: number;
	long: number;
	updatePositionCallback: (position: Position) => void;
	apiUrl: string;
	copyrightUrl: string;
	mapStyle: MapStyle;
	miniMapApiUrl: string;
	miniMapLabel: string;
	toggleMapModeCallback: () => void;
	getPositionErrorCallback: (error: ErrorType) => void;
}

export const GeoPositionMapDialog = ({
	showMapDialog,
	setShowMapDialog,
	lat,
	long,
	updatePositionCallback,
	apiUrl,
	copyrightUrl,
	getPositionErrorCallback,
	mapStyle,
	...miniMapSettings
}: Props) => {
	const [formPosition, setFormPosition] = useState<Position>({
		lat,
		long,
	});

	useEffect(() => {
		setFormPosition({ lat, long });
	}, [showMapDialog, lat, long]);

	const { t } = useTranslation();

	return (
		<FormModal
			large
			onCancel={() => {
				setShowMapDialog(false);
			}}
			onOk={() => {
				updatePositionCallback(formPosition);
				setShowMapDialog(false);
			}}
			padded={false}
			show={showMapDialog}
		>
			<div className="modalMapWrapper">
				<div className="modalMapContainer">
					<GeoPositionMap
						apiUrl={apiUrl}
						copyrightUrl={copyrightUrl}
						isReadonly={false}
						lat={formPosition.lat || 0}
						long={formPosition.long || 0}
						mapStyle={mapStyle}
						updatePositionCallback={setFormPosition}
						{...miniMapSettings}
					/>
					<div className="positionWrapper">
						<p>{`${t("display:labelLatitude")}: ${formatInput(formPosition.lat)} | ${t(
							"display:labelLongitude",
						)}: ${formatInput(formPosition.long)}`}</p>
					</div>
				</div>
				<div className="modalMapDetails">
					<p className="she-components-control-question">
						{t("display:hintRefineLocation")}
					</p>

					<Button
						icon={<PositionIcon />}
						onClick={() =>
							GetPosition(getPositionErrorCallback, (lat: number, long: number) =>
								setFormPosition({ lat, long }),
							)
						}
					>
						{t("display:buttonFindCurrentLocation")}
					</Button>
				</div>
			</div>
		</FormModal>
	);
};
