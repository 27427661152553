import { t } from "i18next";
import type { MultiSelectField } from "../../models/fields/MultiSelectField";

export class MultiSelectFieldValidator {
	multiSelectField: MultiSelectField;
	messages: string[];

	constructor(multiSelectField: MultiSelectField) {
		this.multiSelectField = multiSelectField;
		this.messages = [];
	}

	isFieldValid = (value?: number[]): boolean => {
		let result = true;
		this.messages = [];

		if (this.multiSelectField.isMandatory) {
			if (value == null || value.filter(Boolean).length === 0) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				result = false;
			}
		}

		return result;
	};
}
