import { useTranslation } from "react-i18next";
import "./Error.styles.scss";

interface Props {
	message?: any[];
	redirectUrl?: string;
	pageNotFound?: boolean;
}

export const Error = ({ message, pageNotFound }: Props) => {
	const { t } = useTranslation();

	let errorMessage: string;
	if (message) {
		errorMessage = String(t(message[0], message[1]));
	} else if (pageNotFound) {
		errorMessage = t("error:pageNotFound");
	} else {
		errorMessage = t("error:generic");
	}

	return (
		<div className="she-error-message-container">
			<h2>{t("display:labelErrorTitle")}</h2>
			<h3>{errorMessage}</h3>
		</div>
	);
};
