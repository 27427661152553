import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { questionnaireReducer } from "./components/questionnaire";
import { persistStateMiddleware } from "./middleware";
import { forceLoginGuard } from "./middleware/forceLogingGuard/forceLoginGuard";
import { validationMiddleware } from "./middleware/validationMiddleware";
import { authReducer } from "./components/auth";
import { commonReducer } from "./components/common";
import { portalReducer } from "./components/portal";
import { orgunitReducer } from "./components/orgUnit";
import { attachmentsReducer } from "./components/attachment";
import type { ThunkDispatch } from "redux-thunk";
import type { AppAction } from "./model";

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
	? window.__REDUX_DEVTOOLS_EXTENSION__()
	: (noop: () => void) => noop;

const reducers = combineReducers({
	auth: authReducer,
	common: commonReducer,
	questionnaire: questionnaireReducer,
	portal: portalReducer,
	orgUnit: orgunitReducer,
	recordAttachments: attachmentsReducer,
});

export type AppState = ReturnType<typeof reducers>;

export const store = createStore(
	reducers,
	undefined,
	compose(
		applyMiddleware(thunk, forceLoginGuard, persistStateMiddleware, validationMiddleware),
		reduxDevTools,
	),
);

export type AppDispatch = ThunkDispatch<AppState, unknown, AppAction>;
