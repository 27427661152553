import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SectionType } from "../../../../../../models/questionnaire";
import { ScrollRootToTop } from "../../../../../components/ScrollRootToTop";
import { useTextWithUrl } from "../../../../../utilities/UseTextWithUrl";
import type { State } from "../../../../../../state";
import type { Section } from "../../../../../../models/questionnaire";

export const GuidanceSection = forwardRef<HTMLHeadingElement>((_, ref) => {
	const { t } = useTranslation();

	const iqGuidanceSection = useSelector<State, Section | undefined>((s) =>
		s.questionnaire.sections.find((x) => x.type === SectionType.Guidance),
	);

	const guidanceWithUrl = useTextWithUrl(iqGuidanceSection && iqGuidanceSection.guidance);

	if (!iqGuidanceSection) {
		return null;
	}

	return (
		<>
			<ScrollRootToTop />
			<h1 className="screen-reader-only" ref={ref} tabIndex={-1}>
				{t("display:labelIqTemplateGuidance")}
			</h1>
			{(guidanceWithUrl || "") && (
				<div className="she-components-questionnaire-group-guidance">
					{guidanceWithUrl || ""}
				</div>
			)}
			{(iqGuidanceSection.mediaGuidance || "") && (
				<div
					dangerouslySetInnerHTML={{
						__html: (iqGuidanceSection.mediaGuidance || "").replace(
							/<a href=/g,
							'<a target="_blank" href=',
						),
					}}
				/>
			)}
		</>
	);
});

GuidanceSection.displayName = "GuidanceSection";
