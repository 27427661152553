import { DefaultAuthService } from "../../../../services/auth/implementations/default/defaultAuthService";
import { CommonActionTypes } from "../../common/actions/types";
import { DefaultLocalDataService } from "../../../../services/localData";
import { isBrowserIE } from "../../../../helpers/ieHelper";
import {
	clearPreSignInURL,
	getUserManager,
	storePreSignInURL,
} from "../../../../helpers/AuthenticationHelpers";
import { ErrorWithData } from "../../../../models/errors/ErrorWithData";
import { store } from "../../..";
import { PortalAuthenticationMode } from "../../../../models/portal";
import { logException } from "../../../../services/log";
import { AuthActionTypes } from "./types";
import type { AppDispatch } from "../../..";

export const login = (customerKey: string, portalKey: string) => {
	return async (dispatch: AppDispatch) => {
		try {
			const localDataService = new DefaultLocalDataService();
			const authSevice = new DefaultAuthService({
				subdomain: "",
			});

			let isSigninRedirect;

			let token = await authSevice.authenticate(customerKey, portalKey).catch((error) => {
				if (error instanceof ErrorWithData && error.status === 401) {
					isSigninRedirect = true;
					getUserManager()
						.then((userManager) => {
							storePreSignInURL();
							return userManager.signinRedirect();
						})
						.catch(() => {
							clearPreSignInURL();
						});
				}
			});

			if (!token) {
				token = await localDataService.getAuthTokenByCustomerKey(customerKey);
			}

			if (token) {
				dispatch({
					type: AuthActionTypes.LoggedIn,
					token: token.Token,
					customerKey: token.CustomerKey,
				});
				if (!isBrowserIE()) {
					localDataService.saveAuthenticationToken({
						Token: token.Token,
						CustomerKey: token.CustomerKey,
					});
				}
			} else if (!isSigninRedirect) {
				dispatch({
					type: CommonActionTypes.ErrorHappened,
					key: "error:unauthorized",
					hasError: true,
				});
			}
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:unauthorized",
				hasError: true,
			});
		}
	};
};

export const forceLoginModal = () => {
	const portal = store.getState().portal.portals[0];
	if (!portal || (portal && portal.authenticationMode !== PortalAuthenticationMode.Public)) {
		store.dispatch({ type: AuthActionTypes.ForceLoginModal });
	}
};
