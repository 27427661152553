import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ErrorViewer.styles.scss";

interface Props {
	error?: unknown;
}

export const ErrorViewer = ({ error }: Props) => {
	const { t } = useTranslation();
	const [displayError, setDisplayError] = useState(false);

	let errorMessage: string;

	const isError = error !== null && error !== undefined;

	if (Array.isArray(error)) {
		errorMessage = error[0];
	} else if (typeof error === "string") {
		errorMessage = error;
	} else {
		errorMessage = JSON.stringify(error);
	}

	return (
		<div className="she-error-viewer">
			{!displayError && (
				<button
					className="she-btn she-btn-tertiary"
					onClick={() => {
						setDisplayError(true);
					}}
					type="button"
				>
					{t("display:labelDisplayError")}
				</button>
			)}
			{displayError && isError && <p>{errorMessage}</p>}
		</div>
	);
};
