import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FocusTrap } from "focus-trap-react";
import classNames from "classnames";
import { AnimationWrapper } from "../../../../../components/animation/AnimationWrapper";
import { AnimationSlideFade } from "../../../../../components/animation/AnimationSlideFade";
import { ReactComponent as ArrowDown } from "../../../../../assets/svg/arrow-down-1.svg";
import type { Section } from "../../../../../../models/questionnaire";
import "./SectionMobileNav.styles.scss";

interface Props {
	sections: Section[] | undefined;
	selectedSection: Section | undefined;
	sectionsWithValidationErr: number[];
	sectionPosition: number;
	isSelectorOpened: boolean;
	changeSection: (index: number) => void;
	closeMenu: () => void;
	toggleMenu: () => void;
}

export const SectionMobileNavPresentation = ({
	changeSection,
	closeMenu,
	isSelectorOpened,
	sectionPosition,
	sections,
	sectionsWithValidationErr,
	selectedSection,
	toggleMenu,
}: Props) => {
	const { t } = useTranslation();

	const sectionIndex = selectedSection ? selectedSection.orderIndex : -1;

	const scrollRef = useRef<HTMLLIElement | null>(null);
	const listRef = useRef<HTMLUListElement | null>(null);
	const initialFocusRef = useRef<HTMLButtonElement | null>(null);

	const [animationFinished, setAnimationFinished] = useState(false);

	useEffect(() => {
		if (isSelectorOpened) {
			if (scrollRef.current && listRef.current) {
				const nodeRect = scrollRef.current.getBoundingClientRect();
				if (nodeRect.top + nodeRect.height > window.innerHeight) {
					scrollRef.current.scrollIntoView(false);
				}
			}
		} else if (animationFinished) {
			setAnimationFinished(false);
		}
	}, [isSelectorOpened, animationFinished]);

	useEffect(() => {
		if (isSelectorOpened) {
			if (listRef.current) {
				listRef.current.style.maxHeight = `calc(${
					Math.min(window.outerHeight, window.innerHeight) -
					listRef.current.getBoundingClientRect().top
				}px)`;
			}
		}
	}, [isSelectorOpened]);

	return (
		<FocusTrap
			active={isSelectorOpened}
			focusTrapOptions={{
				initialFocus: () => initialFocusRef.current ?? undefined,
				delayInitialFocus: true,
				escapeDeactivates: (_e) => {
					closeMenu();
					return true;
				},
			}}
		>
			<div
				aria-label={t("display:ariaLabelFormNavigation")}
				aria-modal={isSelectorOpened}
				className={classNames(
					"she-section-mobile-nav",
					isSelectorOpened && "she-section-mobile-nav-open",
				)}
				role={isSelectorOpened ? "dialog" : undefined}
			>
				<div
					aria-hidden="true"
					className="she-section-mobile-nav-overlay"
					onClick={() => {
						closeMenu();
					}}
				/>
				<div
					className="she-section-mobile-nav-current"
					onClick={() => {
						toggleMenu();
					}}
					onKeyDown={(event) => {
						if (event.key === "Enter" || event.key === " ") {
							toggleMenu();
						}
					}}
					role="button"
					tabIndex={0}
				>
					<div className="screen-reader-only">
						{isSelectorOpened
							? t("display:labelNavigationCloseFormNavigation")
							: t("display:labelNavigationOpenFormNavigation")}
					</div>
					<div className="she-section-mobile-nav-current-details">
						<div className="she-section-mobile-nav-current-name">
							{selectedSection?.name}
						</div>
						<div className="she-section-mobile-nav-current-number">
							{t("display:labelSectionOf", {
								sectionNum: sectionPosition,
								howMany: sections ? sections.length : 0,
							})}
						</div>
					</div>
					<ArrowDown
						className={`she-section-mobile-nav-icon ${
							isSelectorOpened ? "she-section-mobile-nav-icon-opened" : ""
						}`}
					/>
				</div>
				<div
					className={
						isSelectorOpened
							? "she-section-mobile-nav-list she-section-mobile-nav-list-open"
							: "she-section-mobile-nav-list"
					}
				>
					<ul ref={listRef}>
						<AnimationWrapper>
							{sections?.map((section, i) => {
								if (!isSelectorOpened) {
									return null;
								}
								const hasValidation: boolean = sectionsWithValidationErr.includes(
									section.id,
								);
								return (
									<AnimationSlideFade
										as="li"
										key={section.id}
										onAnimationComplete={() => setAnimationFinished(true)}
									>
										<span
											ref={
												section.orderIndex === sectionIndex
													? scrollRef
													: undefined
											}
										>
											<button
												className={classNames(
													section.orderIndex === sectionIndex
														? "she-section-mobile-nav-list-item she-section-mobile-nav-list-item-current"
														: "she-section-mobile-nav-list-item",
													hasValidation &&
														"she-section-mobile-nav-list-item-validation",
												)}
												onClick={() => {
													changeSection(section.orderIndex);
												}}
												ref={i === 0 ? initialFocusRef : undefined}
												type="button"
											>
												{section.name}
											</button>
										</span>
									</AnimationSlideFade>
								);
							})}
						</AnimationWrapper>
					</ul>
				</div>
			</div>
		</FocusTrap>
	);
};
