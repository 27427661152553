import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { createNote, deleteNote, editNote } from "../../../../../../state/components/questionnaire";
import { NoteDialog } from "../NoteDialog";
import { ConfirmationModal } from "../../../../../components/modals";
import { useAppDispatch } from "../../../../../../state/hooks/useAppDispatch";
import { NoteListPresentation } from "./NoteList.presentation";
import type { State } from "../../../../../../state";
import type { Note } from "../../../../../../models/questionnaire";
import type { Field } from "../../../../../../models/fields/Field";

interface Props {
	fieldGuid: string;
	questionnaireId: string;
	fieldId: number;
}

export const NoteListContainer = ({ fieldGuid, fieldId, questionnaireId }: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const notes = useSelector<State, Note[] | undefined>((state) =>
		state.questionnaire.notes.filter((x) => x.fieldGuid === fieldGuid),
	);

	const field = useSelector<State, Field | undefined>((state) =>
		state.questionnaire.fields.find((f) => f.guid === fieldGuid),
	);
	const notesLength = useMemo(() => (field ? field.notesLength : undefined), [field]);

	const [isOpen, setIsOpen] = useState(false);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const [noteRecord, setNoteRecord] = useState<Note | undefined>(undefined);
	const [noteRecordToDelete, setNoteRecordToDelete] = useState<Note | undefined>(undefined);
	const [noteRecordToEdit, setNoteRecordToEdit] = useState<boolean>(false);
	const [deleteMessage, setDeleteMessage] = useState("");

	useEffect(() => {
		if (noteRecord) {
			setIsOpen(true);
		}
	}, [noteRecord]);

	if (!notes) {
		return null;
	}

	const showNotesHeader = () => {
		if (notes.length > 0) {
			return true;
		}
		return false;
	};

	const addNote = () => {
		setNoteRecord({
			id: uuid(),
			fieldGuid,
			value: "",
			questionnaireId,
			fieldId,
		});
		setIsOpen(true);
	};

	const cancelNote = () => {
		setIsOpen(false);
		setNoteRecord(undefined);
	};

	const editNoteRecord = (note: Note) => {
		setNoteRecordToEdit(true);
		setNoteRecord(note);
		setIsOpen(true);
	};

	const removeNote = () => {
		if (noteRecordToDelete) {
			dispatch(deleteNote(noteRecordToDelete));
			setNoteRecordToDelete(undefined);
		}

		setDeleteConfirmOpen(false);
	};

	const removeNoteRecord = (note: Note) => {
		setNoteRecordToDelete(note);
		setDeleteMessage(t("display:labelDeleteNoteAreYouSure"));
		setDeleteConfirmOpen(true);
	};

	const saveNoteRecord = (note: string) => {
		if (noteRecord) {
			if (noteRecordToEdit) {
				dispatch(
					editNote({
						id: noteRecord.id,
						fieldGuid,
						value: note,
						questionnaireId,
						fieldId,
					}),
				);
			} else {
				dispatch(
					createNote({
						id: noteRecord.id,
						fieldGuid,
						value: note,
						questionnaireId,
						fieldId,
					}),
				);
			}
		}

		setNoteRecordToEdit(false);
		setNoteRecord(undefined);
		setIsOpen(false);
	};

	return (
		<>
			<NoteListPresentation
				addNote={addNote}
				edit={editNoteRecord}
				notes={notes}
				remove={removeNoteRecord}
				showNotesHeader={showNotesHeader}
			/>
			<NoteDialog
				cancelNote={cancelNote}
				isOpen={isOpen}
				noteLength={notesLength}
				noteRecord={noteRecord && noteRecord}
				saveNote={saveNoteRecord}
			/>
			<ConfirmationModal
				onCancel={() => {
					setDeleteConfirmOpen(false);
				}}
				onConfirm={() => {
					removeNote();
				}}
				show={deleteConfirmOpen}
				text={deleteMessage}
			/>
		</>
	);
};
