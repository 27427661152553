import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { useAuth } from "oidc-react";
import { DefaultAuthService } from "../../../../../services/auth";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { PasswordInput } from "../PasswordInput/PasswordInput";
import { ReactComponent as ErrorIllustration } from "../../../../assets/svg/illustrations/caution.svg";
import { Loading } from "../../../../components/Loading";
import { NotificationModal } from "../../../../components/modals";
import { SignOutModal } from "../../../../components/modals/SignOutModal";
import { APIHealthContext } from "../../../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../../../services/health/implementations/healthMonitor";
import "./PasswordForm.styles.scss";

interface IFormInput {
	OldPassword: string;
	NewPassword: string;
	ConfirmPassword: string;
	isSubmitting: boolean;
}

type Input = keyof IFormInput;

export const PasswordForm = () => {
	const { userData, signIn } = useAuth();
	const isPasswordChangeRequired = !!(
		userData &&
		userData.profile &&
		userData.profile.forcePasswordChange
	);
	const { t } = useTranslation();
	const history = useHistory();

	const [displayValidationError, setDisplayValidationError] = useState(false);
	const [isPasswordChanged, setIsPasswordChanged] = useState(false);
	const [serverErrors, setServerErrors] = useState("");
	const [openSignOutModal, setOpenSignOutModal] = useState(false);
	const health = useContext(APIHealthContext);
	const isOffline = health !== OnlineStatus.Available;
	const customerKey = window.location.pathname.split("/")[1];
	const portalKey = window.location.pathname.split("/")[3];
	const authService = new DefaultAuthService({
		subdomain: "",
	});

	const { data, isLoading, isError } = useQuery({
		queryKey: ["passwordRules"],

		queryFn: async () => {
			return await authService.getPasswordRules(customerKey, portalKey);
		},
	});

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isSubmitting },
	} = useForm<IFormInput>({
		criteriaMode: "all",
	});

	const onSubmit = (data: IFormInput) => {
		authService
			.changePassword(customerKey, portalKey, data)
			.then(({ ErrorMessages, PasswordChangeAllowed }) => {
				if (ErrorMessages.length > 0) {
					const errors = ErrorMessages.join("\r\n");
					setServerErrors(errors);
					setDisplayValidationError(true);
				}
				if (PasswordChangeAllowed) {
					setIsPasswordChanged(true);
				}
			})
			.catch(() => {
				setServerErrors("");
				setDisplayValidationError(true);
			});
	};

	const handleCloseSignOutModal = () => {
		setOpenSignOutModal(false);
	};

	const inputs = [
		{
			label: t("display:authentication.labelCurrentPassword"),
			...register("OldPassword", {
				required: `${t("validation:passwordValidation.required")}`,
			}),
		},
		{
			label: t("display:authentication.labelNewPassword"),
			...register("NewPassword", {
				required: `${t("validation:passwordValidation.required")}`,
				...(data && data.PasswordMinLength
					? {
							minLength: {
								value: data.PasswordMinLength,
								message: `${t("validation:passwordValidation.minLength", {
									minLength: data.PasswordMinLength,
								})}`,
							},
					  }
					: {}),
				validate: {
					specialCharacter: (value) => {
						if (data.PasswordIncludeSymbol) {
							return (
								/[!@#$%^&*]/.test(value) ||
								`${t("validation:passwordValidation.specialCharacter")}`
							);
						}
					},
					numericCharacter: (value) => {
						if (data.PasswordIncludeNumeric) {
							return (
								/(?=.*[0-9])/.test(value) ||
								`${t("validation:passwordValidation.numericCharacter")}`
							);
						}
					},
					lowerCaseAlphabetical: (value) => {
						if (data.PasswordIncludeLowercase) {
							return (
								/(?=.*[a-z])/.test(value) ||
								`${t("validation:passwordValidation.lowerCaseAlphabetical")}`
							);
						}
					},
					upperCaseAlphabetical: (value) => {
						if (data.PasswordIncludeUppercase) {
							return (
								/(?=.*[A-Z])/.test(value) ||
								`${t("validation:passwordValidation.upperCaseAlphabetical")}`
							);
						}
					},
				},
			}),
		},
		{
			label: t("display:authentication.labelConfirmPassword"),
			...register("ConfirmPassword", {
				required: `${t("validation:passwordValidation.required")}`,
				validate: {
					matchPassword: (value) => {
						return (
							value === watch("NewPassword") ||
							`${t("validation:passwordValidation.matchPassword")}`
						);
					},
				},
			}),
		},
	];

	return (
		<div className="she-password-page-container">
			<SignOutModal onCancel={handleCloseSignOutModal} show={openSignOutModal} />
			<IllustratedThemedModal
				cancelText={t("global:ok")}
				onCancel={() => {
					setDisplayValidationError(false);
				}}
				show={displayValidationError}
				text={serverErrors || t("display:labelSubmitErrorMessage")}
			>
				<ErrorIllustration />
			</IllustratedThemedModal>
			<IllustratedThemedModal
				cancelText={t("global:ok")}
				onCancel={() => history.push("settings")}
				show={isError}
				text={t("display:labelSubmitErrorMessage")}
			>
				<ErrorIllustration />
			</IllustratedThemedModal>
			<IllustratedThemedModal
				cancelText={t("global:ok")}
				onCancel={() => history.push("settings")}
				show={isOffline}
				text={t("error:noPageAvailableOffline")}
			>
				<ErrorIllustration />
			</IllustratedThemedModal>
			<Loading
				show={isSubmitting || isLoading}
				showEntryAnimation={false}
				text={t("display:labelLoading")}
			/>
			<NotificationModal
				closeText={t("global:ok")}
				content={t("display:authentication.labelPasswordChanged")}
				onClose={signIn}
				show={isPasswordChanged}
				showEntryAnimation={false}
			/>
			<form onSubmit={handleSubmit(onSubmit)}>
				<h2>{t("display:authentication.changePassword")}</h2>

				{isPasswordChangeRequired ? (
					<p>{t("display:authentication.changePasswordRequired")}</p>
				) : null}

				{inputs &&
					inputs.map((input, index) => {
						const singleInputErrors = errors[input.name as Input];

						return (
							<PasswordInput errors={singleInputErrors} input={input} key={index} />
						);
					})}
				<div className="she-password-buttons">
					{isPasswordChangeRequired ? (
						<button
							className="she-btn she-btn-secondary she-btn-fullwidth"
							onClick={() => setOpenSignOutModal(true)}
							type="button"
						>
							{t("display:authentication.logout")}
						</button>
					) : (
						<button
							className="she-btn she-btn-secondary she-btn-fullwidth"
							onClick={() => history.push("settings")}
							type="button"
						>
							{t("global:cancel")}
						</button>
					)}

					<button className="she-btn she-btn-primary she-btn-fullwidth" type="submit">
						{t("display:buttonSave")}
					</button>
				</div>
			</form>
		</div>
	);
};
