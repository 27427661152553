import { QuestionnaireEncryptionVersion } from "../models/questionnaire";
import { getEncryptionKey } from "./AuthenticationHelpers";
import { encryptArrayBuffer } from "./EncryptionHelper";
import type { User } from "oidc-client-ts";
import type { Portal } from "../models/portal";
import type { StoredAttachment, Attachment } from "../models/attachments/Attachment";

export const convertBlobFilesToArrayBufferType = (
	attachments: Attachment[],
	user: User | null,
): Promise<StoredAttachment[]> => {
	return Promise.all(
		attachments.map(async (a) => {
			const attachmentToStore = { ...a } as StoredAttachment;
			await convertBlobToArrayBuffer(a.file).then((value) => {
				if (value instanceof ArrayBuffer && user) {
					const key = getEncryptionKey(user, QuestionnaireEncryptionVersion.V2);
					attachmentToStore.file = encryptArrayBuffer(value, key as Uint8Array);
					attachmentToStore.userId = user.profile.sub;
					attachmentToStore.encryptionVersion = QuestionnaireEncryptionVersion.V2;
				} else {
					attachmentToStore.file = value;
				}
			});
			return attachmentToStore;
		}),
	);
};

const convertBlobToArrayBuffer = (blob: Blob): Promise<string | ArrayBuffer | null> => {
	return new Promise((resolve) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			resolve(reader.result);
		};

		reader.readAsArrayBuffer(blob);
	});
};

export const transformPortalForStorage = async (portal: Portal) => {
	const portalToSave = { ...portal };
	if (portalToSave.logo) {
		const fileAsArrayBuffer = await convertBlobToArrayBuffer(portalToSave.logo);
		if (fileAsArrayBuffer instanceof ArrayBuffer) {
			portalToSave.storedLogo = fileAsArrayBuffer;
		}
		portalToSave.logo = undefined;
	}
	portalToSave.questionnaireStubs = [...portalToSave.questionnaireStubs];
	for (let i = 0; i < portalToSave.questionnaireStubs.length; i++) {
		const stub = { ...portalToSave.questionnaireStubs[i] };
		if (stub.image) {
			const fileAsArrayBuffer = await convertBlobToArrayBuffer(stub.image);
			if (fileAsArrayBuffer instanceof ArrayBuffer) {
				stub.storedImage = fileAsArrayBuffer;
			}
			stub.image = undefined;
		}
		portalToSave.questionnaireStubs[i] = stub;
	}
	return portalToSave;
};

export const transformPortalForView = (portal: Portal | undefined) => {
	if (portal) {
		if (portal.storedLogo && portal.storedLogo instanceof ArrayBuffer) {
			portal.logo = new Blob([portal.storedLogo]);
			portal.storedLogo = undefined;
		}
		portal.questionnaireStubs.forEach((stub) => {
			if (stub.storedImage && stub.storedImage instanceof ArrayBuffer) {
				stub.image = new Blob([stub.storedImage]);
				stub.storedImage = undefined;
			}
		});
	}
	return portal;
};
