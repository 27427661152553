import { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DateInputPresentation } from "../DateInput/DateInput.presentation";
import { TimeInputPresentation } from "../TimeInput/TimeInput.presentation";
import { DateTimeFieldValidator } from "../../../../validators/dateTimeFieldValidator";
import {
	getDateFormat,
	splitDateToObject,
	removeSpaces,
	splitTimeToObject,
	getPortalCulture,
	getIQDateBeforeRange,
	getIQDateAfterRange,
} from "../../../../helpers/DateTimeInputHelper";
import { FieldType } from "../../../../models/questionnaire/Enums";
import { updateFieldProperty } from "../../../../state/components/questionnaire";
import { UpdatableFieldProperty } from "../../../../state/components/questionnaire/actions/enums";
import { useAppDispatch } from "../../../../state/hooks/useAppDispatch";
import type { DateTimeField } from "../../../../models/fields/DateTimeField";
import type { IQDateTimeField } from "../../../../models/fields/IQDateTimeField";

interface Props {
	field: DateTimeField | IQDateTimeField;
	startDate: Date;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const DateTimeInputContainer = ({
	field,
	startDate,
	updateField,
	updateValidationStatus,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [hint, setHint] = useState<string>("");
	const [minDate, setMinDate] = useState<Date | undefined>(undefined);
	const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);
	const [format, setFormat] = useState<string>("");
	const portalCulture = getPortalCulture();
	const [minMaxUpdated, setMinMaxUpdated] = useState<boolean>(false);
	const dateTimeFieldValidator = useMemo(() => {
		return new DateTimeFieldValidator(field);
	}, [field]);

	const dateTimeValues = {
		day: field.value && field.value.day ? field.value.day : "",
		month: field.value && field.value.month ? field.value.month : "",
		year: field.value && field.value.year ? field.value.year : "",
		hour: field.value && field.value.hour ? field.value.hour : "",
		minute: field.value && field.value.minute ? field.value.minute : "",
		amPm:
			field.value && field.value.amPm ? field.value.amPm : portalCulture === "Us" ? "am" : "",
	};

	const generateDateFormat = () => {
		const formatToUse = getDateFormat();
		setFormat(formatToUse);
		generateHint(formatToUse);
	};

	const generateHint = (hintFormat: string) => {
		let hintToUse = "";

		if (!minDate && field.min) {
			setMinDate(moment(field.min).subtract(1, "days").toDate());
			hintToUse = t("display:hintDateFieldMin", {
				minValue: moment(field.min).format(hintFormat),
				interpolation: { escapeValue: false },
			});
		}

		if (!maxDate && field.max) {
			setMaxDate(moment(field.max).add(1, "days").toDate());
			hintToUse = t("display:hintDateFieldMax", {
				maxValue: moment(field.max).format(hintFormat),
				interpolation: { escapeValue: false },
			});
		}

		if (field.min && field.max) {
			hintToUse = t("display:hintDateFieldMinMax", {
				minValue: moment(field.min).format(hintFormat),
				maxValue: moment(field.max).format(hintFormat),
				interpolation: { escapeValue: false },
			});
		}

		setHint(hintToUse);
	};

	const handleDateChange = (returnedValues: any) => {
		returnedValues.hour = dateTimeValues.hour;
		returnedValues.minute = dateTimeValues.minute;
		returnedValues.amPm = dateTimeValues.amPm;

		dateTimeFieldValidator.isFieldValid(returnedValues);
		updateValidationStatus(field.id, dateTimeFieldValidator.messages);

		updateField(field.id, returnedValues);
	};

	const handleTimeChange = (returnedValues: any) => {
		returnedValues.day = dateTimeValues.day;
		returnedValues.month = dateTimeValues.month;
		returnedValues.year = dateTimeValues.year;

		dateTimeFieldValidator.isFieldValid(returnedValues);
		updateValidationStatus(field.id, dateTimeFieldValidator.messages);
		updateField(field.id, returnedValues);
	};

	const calculateToday = () => {
		const today = moment().format("DD/MM/YYYY");
		const dateValues = splitDateToObject(today.toString());
		handleDateChange(dateValues);
	};

	const calculateTimeNow = () => {
		const timeFormatToUse: string = portalCulture === "Us" ? "hh:mm a" : "HH:mm";
		const timeNow = moment().format(timeFormatToUse);
		const timeValues = splitTimeToObject(timeNow.toString());
		handleTimeChange(timeValues);
	};

	if (!format) {
		generateDateFormat();
	}

	useEffect(() => {
		if (minMaxUpdated === true) {
			generateDateFormat();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minMaxUpdated]);

	useEffect(() => {
		if (field.type === FieldType.IQDateTime) {
			if (!field.min && field.LimitBeforeType && field.LimitBeforeValue !== undefined) {
				const min = getIQDateBeforeRange(
					startDate,
					field.LimitBeforeValue,
					field.LimitBeforeType,
				);
				setMinDate(min.toDate());
				dispatch(
					updateFieldProperty(field.id, min.toDate(), UpdatableFieldProperty.DateMin),
				);
			}

			if (!field.max && field.LimitAfterType && field.LimitAfterValue !== undefined) {
				const max = getIQDateAfterRange(
					startDate,
					field.LimitAfterValue,
					field.LimitAfterType,
				);
				setMaxDate(max.toDate());
				dispatch(
					updateFieldProperty(field.id, max.toDate(), UpdatableFieldProperty.DateMax),
				);
			}

			setMinMaxUpdated(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<DateInputPresentation
				displayMonthBeforeDay={portalCulture === "Us"}
				guid={field.guid}
				hint={hint}
				isNotApplicable={field.isNotApplicable}
				name={removeSpaces(field.name)}
				onChange={handleDateChange}
				onTodayClick={calculateToday}
				required={field.isMandatory}
				values={dateTimeValues}
			/>

			<TimeInputPresentation
				guid={field.guid}
				isNotApplicable={field.isNotApplicable}
				name={removeSpaces(field.name)}
				onChange={handleTimeChange}
				onNowClick={calculateTimeNow}
				required={field.isMandatory}
				showAmPm={portalCulture === "Us"}
				values={dateTimeValues}
			/>
		</>
	);
};
