import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { LatLngBounds, LatLngBoundsExpression } from "leaflet";

export interface CopyrightInfo {
	minLevel: number;
	maxLevel: number;
	label: string;
	bounds?: LatLngBounds[];
}

export type MapStyle = "satellite.day" | "explore.day";

export function useMapCopyrightInfo(copyrightUrl: string) {
	const { data: queryResult } = useQuery({
		queryKey: [copyrightUrl],
		queryFn: () => axios.get(copyrightUrl),
		gcTime: Infinity,
		staleTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});

	const year = useMemo(() => {
		return new Date().getFullYear();
	}, []);

	const getCopyrightInfo = useCallback(
		(map: L.Map | null, mapStyle: MapStyle) => {
			const data = getCopyrightBounds(queryResult, mapStyle);
			let copyrights = `&copy; ${year} HERE`;
			if (data && map) {
				data.forEach((ci) => {
					if (map.getZoom() >= ci.minLevel && map.getZoom() <= ci.maxLevel) {
						if (ci.bounds) {
							if (
								ci.bounds.some((b) =>
									map.getBounds().intersects(b as LatLngBoundsExpression),
								)
							) {
								if (!copyrights.includes(ci.label)) {
									copyrights += `, ${ci.label}`;
								}
							}
						} else if (!copyrights.includes(ci.label)) {
							// No bounds, always show
							copyrights += `, ${ci.label}`;
						}
					}
				});
			}
			return copyrights;
		},
		[queryResult, year],
	);
	return getCopyrightInfo;
}

export function getCopyrightBounds(result: any, mapStyle: MapStyle): CopyrightInfo[] {
	if (result?.data) {
		let copyInfo: CopyrightInfo[] = [];

		const copyrightSets: string[] = result.data.resources?.base?.styles?.[mapStyle];

		if (copyrightSets) {
			const input = copyrightSets.map((set) => mapBounds(result.data.copyrights[set]));

			copyInfo = input.flat();

			return copyInfo;
		}
	}
	return [];
}

function mapBounds(copyInfo: []) {
	return copyInfo.map((dtoCopy: any): CopyrightInfo => {
		return {
			minLevel: dtoCopy.minLevel,
			maxLevel: dtoCopy.maxLevel,
			label: dtoCopy.label,
			bounds: dtoCopy.boundingBoxes?.map((b: any) => {
				return [
					[b.south, b.west],
					[b.north, b.east],
				];
			}),
		};
	});
}
