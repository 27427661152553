import { useEffect, useMemo, useRef, useState } from "react";
import { Marker, MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { icon as leafletIcon } from "leaflet";
import { useMapCopyrightInfo } from "../../utilities/UseMapCopyrightInfo";
import noInternetImg from "../../assets/png/no-internet-for-map.jpg";
import markerIcon from "../../assets/png/marker-icon.png";
import markerIcon2x from "../../assets/png/marker-icon-2x.png";
import markerShadow from "../../assets/png/marker-shadow.png";
import { MinimapControl } from "./components/MinimapControl/MinimapControl";
import type { MapStyle } from "../../utilities/UseMapCopyrightInfo";
import "./GeoPositionMap.styles.scss";

export interface Position {
	lat?: number;
	long?: number;
}
export interface Props {
	lat: number;
	long: number;
	updatePositionCallback?: (position: Position) => void;
	apiUrl: string;
	copyrightUrl: string;
	isReadonly?: boolean;
	mapStyle: MapStyle;
	miniMapApiUrl?: string;
	miniMapLabel?: string;
	toggleMapModeCallback?: () => void;
}

export const GeoPositionMap = ({
	lat,
	long,
	updatePositionCallback,
	apiUrl,
	copyrightUrl,
	isReadonly,
	mapStyle,
	miniMapApiUrl,
	miniMapLabel,
	toggleMapModeCallback,
}: Props) => {
	const refMarker = useRef<L.Marker>(null);
	const refMap = useRef<L.Map>(null);
	const [isReady, setIsReady] = useState(false);

	const here = {
		url: `${apiUrl}`,
	};

	const getCopyrightInfo = useMapCopyrightInfo(copyrightUrl);
	const [copyrightInfo, setCopyrightInfo] = useState(getCopyrightInfo(refMap.current, mapStyle));

	useEffect(() => {
		if (isReadonly) {
			refMap.current?.setView([lat, long], 16);
		}
	}, [isReadonly, lat, long, refMap]);

	useEffect(() => {
		const map = refMap.current;
		const setInfo = () => {
			setCopyrightInfo(getCopyrightInfo(refMap.current, mapStyle));
		};
		if (isReady) {
			setCopyrightInfo(getCopyrightInfo(map, mapStyle));
			map?.addEventListener("zoomend, moveend", setInfo);
		}
		return () => {
			map?.removeEventListener("zoomend, moveend", setInfo);
		};
	}, [getCopyrightInfo, isReady, refMap, mapStyle]);

	const icon = useMemo(
		() =>
			leafletIcon({
				iconUrl: markerIcon,
				shadowUrl: markerShadow,
				iconRetinaUrl: markerIcon2x,
				iconSize: [25, 41],
				iconAnchor: [12, 41],
				shadowSize: [41, 41],
				shadowAnchor: [12, 41],
			}),
		[],
	);

	return (
		<MapContainer
			boxZoom={!isReadonly}
			center={[lat, long]}
			doubleClickZoom={!isReadonly}
			dragging={!isReadonly}
			keyboard={!isReadonly}
			minZoom={2}
			ref={refMap}
			scrollWheelZoom={!isReadonly}
			tap={!isReadonly}
			touchZoom={!isReadonly}
			whenReady={() => {
				setIsReady(true);
			}}
			zoom={16}
			zoomControl={false}
		>
			<TileLayer
				attribution={copyrightInfo}
				bounds={[
					[-90, -180],
					[90, 180],
				]}
				errorTileUrl={noInternetImg}
				noWrap
				url={here.url}
			/>
			<Marker
				autoPan={!isReadonly}
				draggable={!isReadonly}
				eventHandlers={{
					dragend: () => updatePosition(refMarker, updatePositionCallback),
				}}
				icon={icon}
				keyboard={!isReadonly}
				position={[lat, long]}
				ref={refMarker}
			/>
			{!isReadonly ? <ZoomControl position="bottomright" /> : null}
			{!isReadonly && miniMapApiUrl ? (
				<MinimapControl
					label={miniMapLabel}
					onClick={toggleMapModeCallback}
					tileLayerUrl={miniMapApiUrl}
				/>
			) : null}
		</MapContainer>
	);
};

export const updatePosition = (
	refMarker: React.RefObject<L.Marker>,
	updatePosition?: (position: Position) => void,
) => {
	const marker = refMarker.current;

	if (marker !== null) {
		const newPosition = marker.getLatLng().wrap();
		updatePosition?.({ lat: newPosition.lat, long: newPosition.lng });
	}
};
