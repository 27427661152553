import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	FieldType,
	LocalFieldIds,
	LocalSectionId,
	SelectFieldType,
} from "../../../../../models/questionnaire";
import {
	updateField,
	updateFieldProperty,
	updateValidationStatus,
} from "../../../../../state/components/questionnaire";
import { AttachmentParentType } from "../../../../../models/attachments/Attachment";
import { AttachmentsDisplay } from "../../../../components/attachments";
import { AuditInput } from "../../../../components/input/AuditInput";
import { BehaviouralInput } from "../../../../components/input/BehaviouralInput";
import { BooleanInput } from "../../../../components/input/BooleanInput";
import { DateInput } from "../../../../components/input/DateInput";
import { DateTimeInput } from "../../../../components/input/DateTimeInput";
import { EmailInput } from "../../../../components/input/EmailInput";
import { EntitySelectInput } from "../../../../components/input/EntitySelectInput";
import { GeoPositionInput } from "../../../../components/input/GeoPositionInput/GeoPositionInput.container";
import { IQSignatureInput } from "../../../../components/input/IQSignatureInput/IQSignatureInput.container";
import { MatrixInput } from "../../../../components/input/MatrixInput";
import { MultiSelectInput } from "../../../../components/input/MultiSelectInput";
import { NoteDisplay } from "../note/NoteList";
import { NumberInput } from "../../../../components/input/NumberInput";
import { OrgUnitSelector } from "../../../../components/input/OrgUnitSelector";
import { ProjectSelectInput } from "../../../../components/input/ProjectSelectInput";
import { QuestionnaireFieldComments } from "../QuestionnaireFieldComments";
import { QuestionnaireFieldNA } from "../QuestionnaireFieldNA";
import { OptionalRegisterSelect } from "../../../../components/OptionalRegisterSelect";
import { SingleSelectInput } from "../../../../components/input/SingleSelectInput";
import { SubModuleDisplay } from "../submodule/SubModuleList";
import { TextInput } from "../../../../components/input/TextInput";
import { TimeInput } from "../../../../components/input/TimeInput";
import { useAppDispatch } from "../../../../../state/hooks/useAppDispatch";
import { QuestionnaireFieldPresentation } from "./QuestionnaireField.presentation";
import type { State } from "../../../../../state";
import type { SingleSelectField } from "../../../../../models/fields/SingleSelectField";
import type { MultiSelectField } from "../../../../../models/fields/MultiSelectField";
import type { IndirectField } from "../../../../../models/fields/IndirectField";
import type { Field } from "../../../../../models/fields/Field";
import type { EnumSelectField } from "../../../../../models/fields/EnumSelectField";
import type { UpdatableFieldProperty } from "../../../../../state/components/questionnaire/actions/enums";
import type { OptionalSelectField } from "../../../../../models/fields/OptionalSelectField";

interface Props {
	fieldGuid: string;
	updateSubModuleField?: (fieldId: number, value: any, type: FieldType) => void;
}

export const QuestionnaireFieldContainer = ({ fieldGuid, updateSubModuleField }: Props) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const field = useSelector<State, Field | undefined>((state) =>
		state.questionnaire.fields
			.reduce<Field[]>((acc, field) => {
				acc.push(field);
				if (field.relatedFields) {
					field.relatedFields.forEach((field) => acc.push(field));
				}
				return acc;
			}, [])
			.find((field) => field.guid === fieldGuid),
	);
	const questionnaireAttachmentsEnabled = useSelector<State, boolean>(
		(state) =>
			state.questionnaire.questionnaire!.maxAttachments !== undefined &&
			state.questionnaire.questionnaire!.maxAttachments > 0,
	);
	const questionnaireActionsEnabled = useSelector<State, boolean>(
		(state) => state.questionnaire.questionnaire!.allowActions,
	);
	const updateFieldValidationStatus = (fieldId: number, value: string[]) => {
		dispatch(updateValidationStatus(fieldId, value));
	};
	const startDate = useSelector<State, Date>((state) =>
		state.questionnaire.status && state.questionnaire.status.dateCreated
			? state.questionnaire.status.dateCreated
			: new Date(),
	);

	const updateFieldValidationStatusCallback = useCallback(
		(fieldId: number, value: string[]) => dispatch(updateValidationStatus(fieldId, value)),
		[dispatch],
	);

	const updateFieldValue = (
		fieldId: number,
		value: any,
		additionalParams?: Record<string, any>,
	) => {
		if (field && updateSubModuleField) {
			updateSubModuleField(fieldId, value, field.type);
			dispatch(updateField(fieldId, value, false, additionalParams));
		} else {
			dispatch(updateField(fieldId, value, false, additionalParams));
		}
	};

	const updateFieldPropertyValue = (
		fieldId: number,
		value: any,
		propertyName: UpdatableFieldProperty,
	) => {
		dispatch(updateFieldProperty(fieldId, value, propertyName));
	};

	if (!field) {
		return null;
	}
	return (
		<div
			className={`she-components-control ${
				field.validationMessage &&
				field.validationMessage.length > 0 &&
				!field.hideValidationErrors
					? "she-components-validation-border-left"
					: ""
			}`}
		>
			<QuestionnaireFieldPresentation
				data-testid="questionnaire-field"
				guid={field.guid}
				guidance={field.guidance}
				guidanceIsPopup={field.guidanceIsPopup}
				hideValidationErrors={field.hideValidationErrors}
				mediaGuidance={field.mediaGuidance}
				required={field.isMandatory}
				showGuidance={field.showGuidance}
				title={field.name}
				validationErrors={field.validationMessage}
			/>
			{(field.type === FieldType.Text ||
				(field.type === FieldType.Indirect &&
					field.originalField &&
					field.originalField.type !== FieldType.OptionalSelect)) && (
				<TextInput
					data-testid="text-field"
					textField={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Number && (
				<NumberInput
					numberField={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Boolean && (
				<BooleanInput
					booleanField={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Select &&
				field.selectType === SelectFieldType.SingleSelect && (
					<SingleSelectInput
						field={field as SingleSelectField}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
			{field.type === FieldType.Select &&
				field.selectType === SelectFieldType.EnumSingleSelect && (
					<SingleSelectInput
						field={field as EnumSelectField}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
			{field.type === FieldType.Select &&
				field.selectType === SelectFieldType.MultiSelect && (
					<MultiSelectInput
						multiSelectField={field as MultiSelectField}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
			{field.type === FieldType.Date && (
				<DateInput
					field={field}
					startDate={startDate}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Time && (
				<TimeInput
					field={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.DateTime && (
				<DateTimeInput
					field={field}
					startDate={startDate}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Matrix && (
				<MatrixInput
					matrixField={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.GeoPosition && (
				<GeoPositionInput
					geoPositionField={field}
					updateFieldProperty={updateFieldPropertyValue}
					updateValidationStatus={updateFieldValidationStatusCallback}
				/>
			)}
			{field.type === FieldType.OrgUnit && (
				<OrgUnitSelector
					field={field}
					onSelect={(id) => updateFieldValue(LocalFieldIds.OrgUnit, id)}
					selectedId={field.value}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Email && (
				<EmailInput
					emailField={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Audit && (
				<AuditInput
					auditField={field}
					updateFieldProperty={updateFieldPropertyValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.Behavioural && (
				<BehaviouralInput
					behaviouralField={field}
					updateFieldProperty={updateFieldPropertyValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.ProjectSelect && (
				<ProjectSelectInput
					field={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.IQSignature && (
				<IQSignatureInput
					iQSignatureField={field}
					updateFieldProperty={updateFieldPropertyValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.IQDate && (
				<DateInput
					field={field}
					startDate={startDate}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.IQDateTime && (
				<DateTimeInput
					field={field}
					startDate={startDate}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.type === FieldType.EntitySelect && (
				<EntitySelectInput
					field={field}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{(field.type === FieldType.OptionalSelect ||
				((field as IndirectField).originalField &&
					(field as IndirectField).originalField.type === FieldType.OptionalSelect)) && (
				<OptionalRegisterSelect
					field={field as OptionalSelectField}
					updateField={updateFieldValue}
					updateValidationStatus={updateFieldValidationStatus}
				/>
			)}
			{field.isIq && field.allowNotApplicable && <QuestionnaireFieldNA field={field} />}
			{questionnaireAttachmentsEnabled &&
				field.attachmentsEnabled &&
				field.attachmentsMandatory &&
				field.attachmentsMandatory === true && (
					<AttachmentsDisplay
						fieldId={field.id}
						hasValidationErrors={
							field.attachmentValidationMessage &&
							field.attachmentValidationMessage.length > 0
						}
						isMandatory
						parentGuid={field.guid}
						parentType={AttachmentParentType.Field}
						questionnaireId={field.questionnaireId}
						validationErrors={field.attachmentValidationMessage}
					/>
				)}
			{field.actionsEnabled && field.actionsMandatory && field.actionsMandatory === true && (
				<SubModuleDisplay
					hasValidationErrors={
						field.actionValidationMessage && field.actionValidationMessage.length > 0
					}
					isIQ={field.isIq}
					isMandatory
					key={field.guid}
					parentGuid={field.guid}
					parentId={field.id}
					subModuleId={LocalSectionId.Actions}
					validationErrors={field.actionValidationMessage}
				/>
			)}
			{field.isIq &&
				((questionnaireAttachmentsEnabled && field.attachmentsEnabled) ||
					(questionnaireActionsEnabled &&
						field.actionsEnabled &&
						!field.actionsMandatory) ||
					field.notesEnabled ||
					field.comments.isEnabled) && (
					<h1 className="she-components-control-subtitle">{t("labelOptional")}</h1>
				)}

			{questionnaireAttachmentsEnabled &&
				field.attachmentsEnabled &&
				!field.attachmentsMandatory && (
					<AttachmentsDisplay
						fieldId={field.id}
						hasValidationErrors={false}
						isMandatory={false}
						parentGuid={field.guid}
						parentType={AttachmentParentType.Field}
						questionnaireId={field.questionnaireId}
						validationErrors={[]}
					/>
				)}
			{field.actionsEnabled && !field.actionsMandatory && (
				<SubModuleDisplay
					hasValidationErrors={false}
					isIQ={field.isIq}
					isMandatory={false}
					key={field.guid}
					parentGuid={field.guid}
					parentId={field.id}
					subModuleId={LocalSectionId.Actions}
					validationErrors={[]}
				/>
			)}
			<QuestionnaireFieldComments field={field} />
			{field.notesEnabled && (
				<NoteDisplay
					fieldGuid={field.guid}
					fieldId={field.id}
					questionnaireId={field.questionnaireId}
				/>
			)}
		</div>
	);
};
