import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DefaultMatrixFieldService as MatrixFieldService } from "../../../../services/matrixField";
import { MatrixFieldValidator } from "../../../../validators/matrixFieldValidator/matrixFieldValidator";
import { MatrixInputPresentation } from "./MatrixInput.presentation";
import type { Matrix } from "../../../../models/matrix/matrix";
import type { MatrixField } from "../../../../models/fields/MatrixField";
import type { State } from "../../../../state";
import type { MatrixFactor } from "../../../../models/matrix/matrixFactor";

interface Props {
	matrixField: MatrixField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const MatrixInputContainer = ({
	matrixField,
	updateField,
	updateValidationStatus,
}: Props) => {
	const { t } = useTranslation();
	const matrix = useSelector<State, Matrix | undefined>((state) =>
		state.questionnaire.matrixes.find((m) => m.id === matrixField.matrixId),
	);
	const [isSelectorOpen, setIsSelectorOpen] = useState(false);
	const service = useMemo(() => new MatrixFieldService(), []);
	const validator = useMemo(() => new MatrixFieldValidator(matrixField), [matrixField]);

	const getMatrixSettings = (value?: number): { text: string; colour: string } => {
		let text: string = t("display:labelMatrixPleaseSelect");
		if (matrix) {
			const factor: MatrixFactor | undefined = service.getFactor(value, matrix);
			if (factor) {
				switch (matrix.captionType) {
					case 1:
						text = `${factor.value}-${factor.caption}`;
						break;
					case 2:
						text = factor.caption;
						break;
					case 3:
						text = `${factor.value}`;
				}

				return { text, colour: factor.colour };
			}
		}

		return { text, colour: "#C0C0C0" };
	};

	const onClick = () => {
		setIsSelectorOpen(true);
	};

	const onKeyPress = (key: string) => {
		if (key === "Enter" || key === " ") {
			setIsSelectorOpen(true);
		}
	};

	const onClear = () => {
		updateField(matrixField.id, undefined);
		const validationResult = validator.isFieldValid(undefined);
		if (validationResult !== (matrixField.validationMessage.length === 0)) {
			updateValidationStatus(matrixField.id, validator.messages);
		}
	};

	const onSelectorCancel = () => {
		setIsSelectorOpen(false);
	};

	const onSelectorSave = (factorId: number | undefined) => {
		updateField(matrixField.id, factorId);

		const validationResult = validator.isFieldValid(factorId);
		if (validationResult !== (matrixField.validationMessage.length === 0)) {
			updateValidationStatus(matrixField.id, validator.messages);
		}

		setIsSelectorOpen(false);
	};

	const matrixSettings = getMatrixSettings(matrixField.value);

	return (
		<MatrixInputPresentation
			clearLabel={t("global:clear")}
			colour={matrixSettings.colour}
			currentValue={matrixField.value}
			fieldName={matrixField.name}
			isSelectorOpen={isSelectorOpen}
			matrixId={matrixField.matrixId}
			onClear={onClear}
			onClick={onClick}
			onKeyPress={onKeyPress}
			onSelectorCancel={onSelectorCancel}
			onSelectorSave={onSelectorSave}
			text={matrixSettings.text}
		/>
	);
};
