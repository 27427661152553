import React from "react";
import { Card } from "../../Card";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { ReactComponent as Remove } from "../../../assets/svg/remove.svg";
import type { Entity } from "../../../../models/entity";

interface Props {
	entity?: Entity | null;
	buttonLabel: string;
	onClick: (event: React.MouseEvent) => void;
	onRemove: (event: React.MouseEvent) => void;
	removeLabel: string;
}

export const BasicEntitySelectInputPresentation = ({
	entity,
	buttonLabel,
	onClick,
	onRemove,
	removeLabel,
}: Props) => {
	return (
		<>
			<AnimationWrapper>
				{entity?.info && (
					<AnimationSlideFade key={entity.id}>
						<Card
							buttonSlot={
								<button
									aria-label={removeLabel}
									className="she-icon she-icon-gray"
									onClick={onRemove}
									type="button"
								>
									<Remove />
								</button>
							}
							onClick={onClick}
						>
							<dl>
								{entity.info.map((info) =>
									info.detail ? (
										<React.Fragment key={info.propertyName}>
											<dt>{info.title}</dt>
											<dd>{info.detail}</dd>
										</React.Fragment>
									) : null,
								)}
							</dl>
						</Card>
					</AnimationSlideFade>
				)}
			</AnimationWrapper>
			<button className="she-btn she-btn-tertiary" onClick={onClick} type="button">
				{buttonLabel}
			</button>
		</>
	);
};
