import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormModal } from "../modals";
import { editAttachment } from "../../../state/components/attachment/actions/creators";
import { useAppDispatch } from "../../../state/hooks/useAppDispatch";
import { AttachmentDescriptionModalItem } from "./AttachmentDescriptionModalItem";
import type { State } from "../../../state";
import type { Attachment } from "../../../models/attachments/Attachment";
import "./AttachmentDescriptionmodal.styles.scss";

export const AttachmentDescriptionModal = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [attachmentsWithDescriptionChanges, setAttachmentsWithDescriptionChanges] = useState<
		Attachment[]
	>([]);
	const [cachedAttachments, setCachedAttachments] = useState<Attachment[]>([]);

	const listOfEditedAttachments = useSelector<State, string[]>((state) => {
		return state.recordAttachments.listOfEditedAttachments;
	});

	const storedAttachments = useSelector<State, Attachment[]>((state) => {
		return state.recordAttachments.attachments.filter((a) =>
			listOfEditedAttachments.includes(a.id),
		);
	});

	const setAttachmentDescriptionCallback = (id: string, description: string) => {
		const attachment = storedAttachments.find((a) => a.id === id);

		if (attachment) {
			const attachments = attachmentsWithDescriptionChanges.reduce((acc: Attachment[], a) => {
				a.id !== id && acc.push(a);

				return acc;
			}, []);

			setAttachmentsWithDescriptionChanges([...attachments, { ...attachment, description }]);
		}
	};

	const onFormOk = (): void => {
		const attachmentsToUpdate = storedAttachments.map((a) => {
			const updatedAttachment = attachmentsWithDescriptionChanges.find(
				(at) => at.id === a.id,
			);

			return updatedAttachment ? { ...updatedAttachment } : { ...a };
		});

		setCachedAttachments(storedAttachments);
		setAttachmentsWithDescriptionChanges([]);
		attachmentsToUpdate.length > 0 && dispatch(editAttachment(attachmentsToUpdate));
	};

	const renderAttachments = (): JSX.Element[] => {
		const attachmentsList = storedAttachments.length ? storedAttachments : cachedAttachments;

		return attachmentsList.map((a) => (
			<AttachmentDescriptionModalItem
				attachment={a}
				key={a.id}
				updateAttachmentDescriptionCallback={setAttachmentDescriptionCallback}
			/>
		));
	};

	return (
		<FormModal
			data-testid="testinghaha"
			onCancel={() => {}}
			onOk={onFormOk}
			show={storedAttachments.length > 0}
			showCancelBtn={false}
			title={t("display:labelAttachmentDescription")}
		>
			<h3 className="she-attachment-description-modal__h3">
				{t("display:labelAddAttachmentDescriptions")}
			</h3>
			{renderAttachments()}
		</FormModal>
	);
};
