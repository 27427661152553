import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInputContainer } from "../TextInput/TextInput.container";
import { ErrorType, GetPosition } from "../../GeoPosition/GetCurrentPosition";
import { IllustratedThemedModal } from "../../modals/IllustratedThemedModal";
import { ReactComponent as ErrorIllustration } from "../../../assets/svg/illustrations/caution.svg";
import { ReactComponent as NoInternetIllustration } from "../../../assets/svg/illustrations/noInternet.svg";
import { Button } from "../Button";
import { ReactComponent as PositionIcon } from "../../../assets/svg/position.svg";
import { MapValidationType } from "./GeoPositionInput.container";
import { GeoPositionMapDialog } from "./GeoPositionMapDialog";
import { GeoPositionReadOnlyMap } from "./GeoPositionReadOnlyMap";
import type { MapStyle } from "../../../utilities/UseMapCopyrightInfo";
import type { OnlineStatus } from "../../../../services/health/implementations/healthMonitor";
import type { MapValidationMessage } from "./GeoPositionInput.container";
import type { TextFieldValidator } from "../../../../validators/textFieldValidator";
import type { SimplifiedTextField } from "../../../../models/fields/TextField";
import type { Position } from "../../GeoPosition/GeoPositionMap.component";
import "./GeoPosition.styles.scss";

export interface Props {
	long?: number;
	lat?: number;
	description: SimplifiedTextField;
	updateDescription: (value: any) => void;
	updateDescriptionValidationStatus: (value: string[]) => void;
	descriptionValidator: TextFieldValidator;
	descriptionValidationMessages: string[];
	mapValidationMessage?: MapValidationMessage;
	setMapValidationMessage: (value?: MapValidationMessage) => void;
	updatePositionCallback: (position: Position) => void;
	health: OnlineStatus;
	apiKey?: string;
	apiUrl: string;
	copyrightUrl: string;
	staticCopyrightUrl: string;
	streetViewApiUrl: string;
	showMapDialog: boolean;
	setShowMapDialog: (value: boolean) => void;
	miniMapApiUrl: string;
	miniMapLabel: string;
	toggleMapModeCallback: () => void;
	geoPositionValidationMessages: string[];
	mapStyle: MapStyle;
}

export const GeoPositionInputPresentation = ({
	long,
	lat,
	description,
	updateDescription,
	updateDescriptionValidationStatus,
	descriptionValidator,
	descriptionValidationMessages,
	setMapValidationMessage,
	mapValidationMessage,
	updatePositionCallback,
	health,
	apiUrl,
	copyrightUrl,
	staticCopyrightUrl,
	showMapDialog,
	setShowMapDialog,
	streetViewApiUrl,
	geoPositionValidationMessages,
	mapStyle,
	...miniMapSettings
}: Props) => {
	const [awaitingPosition, setAwaitingPosition] = useState(false);

	const { t } = useTranslation();
	const getPositionErrorCallback = (type: ErrorType) => {
		setAwaitingPosition(false);
		if (type === ErrorType.NotEnabled) {
			setMapValidationMessage({
				message: t("error:geoLocationBlocked"),
				bottomMessage: t("error:geoLocationBlocked2"),
				type: MapValidationType.Error,
			});
		} else {
			setMapValidationMessage({
				message: t("error:geoLocationUnableToRetrieve"),
				bottomMessage: t("error:geoLocationUnableToRetrieve2"),
				type: MapValidationType.Error,
			});
		}
	};

	return (
		<div data-testid="geo-position-input">
			{geoPositionValidationMessages.length > 0 && (
				<div className="she-components-validation-message">
					{geoPositionValidationMessages[0]}
				</div>
			)}

			{!lat && !long && (
				<div className="geo-position-input__button">
					<Button
						awaiting={awaitingPosition}
						icon={<PositionIcon />}
						onClick={() => {
							setAwaitingPosition(true);
							GetPosition(getPositionErrorCallback, (lat: number, long: number) => {
								setAwaitingPosition(false);
								updatePositionCallback({ lat, long });
							});
						}}
						variant="secondary"
					>
						{awaitingPosition
							? t("display:buttonFindingLocation")
							: t("display:buttonUseCurrentLocation")}
					</Button>
				</div>
			)}

			{awaitingPosition && (
				<div className="she-components-validation-message searching-message">
					{t("display:labelSearchingForLocation")} <br />{" "}
					{t("display:labelSearchingForLocation2")}
				</div>
			)}

			<IllustratedThemedModal
				bottomText={mapValidationMessage && mapValidationMessage.bottomMessage}
				cancelText={t("global:ok")}
				onCancel={() => {
					setMapValidationMessage();
				}}
				show={mapValidationMessage !== undefined}
				text={mapValidationMessage && mapValidationMessage.message}
			>
				{mapValidationMessage && mapValidationMessage.type === MapValidationType.Error ? (
					<ErrorIllustration />
				) : (
					mapValidationMessage &&
					mapValidationMessage.type === MapValidationType.NoInternet && (
						<NoInternetIllustration />
					)
				)}
			</IllustratedThemedModal>

			{lat && long && (
				<GeoPositionReadOnlyMap
					apiUrl={streetViewApiUrl}
					copyrightUrl={staticCopyrightUrl}
					health={health}
					lat={lat}
					long={long}
					mapStyle="explore.day"
					setMapValidationMessage={setMapValidationMessage}
					setShowMapDialog={setShowMapDialog}
					updatePositionCallback={updatePositionCallback}
				/>
			)}

			<GeoPositionMapDialog
				apiUrl={apiUrl}
				copyrightUrl={copyrightUrl}
				getPositionErrorCallback={getPositionErrorCallback}
				lat={lat || 0}
				long={long || 0}
				mapStyle={mapStyle}
				setShowMapDialog={setShowMapDialog}
				showMapDialog={showMapDialog}
				updatePositionCallback={updatePositionCallback}
				{...miniMapSettings}
			/>

			<div
				className={`${
					descriptionValidationMessages && descriptionValidationMessages.length > 0
						? "she-components-validation-border-left"
						: ""
				}`}
			>
				<TextInputContainer
					textField={description}
					updateField={updateDescription}
					updateValidationStatus={updateDescriptionValidationStatus}
					validator={descriptionValidator}
				/>
			</div>
		</div>
	);
};
