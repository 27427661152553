import { useEffect, useMemo } from "react";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { ActionAttachmentsList } from "../ActionAttachmentsList";
import { ActionField } from "../ActionField";
import { ActionReadOnlyField } from "../ActionReadOnlyField/ActionReadOnlyField.component";
import { BasicDateInput } from "../../../../components/input/BasicDateInput";
import { BasicFileInput } from "../../../../components/input/BasicFileInput/BasicFileInput.component";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { ReactComponent as Calendar } from "../../../../assets/svg/calendar.svg";
import { useActionLabelAndGuidance } from "../../hooks/useActionLabelAndGuidance";
import type { ActionAttachment, ActionFull } from "../../../../../models/action";
import "./ActionComplete.styles.scss";

interface Props {
	action: ActionFull;
	addAttachment: (file: File, identifier?: string, description?: string) => void;
	attachmentsToDisplay: ActionAttachment[];
	removeAttachment: (identifier: string) => void;
	resetAttachments: () => void;
}

export const ActionComplete = ({
	action,
	addAttachment,
	attachmentsToDisplay,
	removeAttachment,
	resetAttachments,
}: Props) => {
	useEffect(() => {
		resetAttachments();
		// Make sure it runs only once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { t } = useTranslation();
	const { control, formState } = useFormContext();

	const formatDate = (date: string) => moment(date).format(getDateFormat());

	const formattedDueDate = useMemo(() => formatDate(action.DueDate), [action.DueDate]);
	const formattedDateCreated = useMemo(
		() => formatDate(action.DateCreated),
		[action.DateCreated],
	);
	const formattedDateCompleted = useMemo(() => moment().format(getDateFormat()), []);

	const completedDateField = useActionLabelAndGuidance("CompleteDate", action.Info);
	const noteCommentField = useActionLabelAndGuidance("NoteComment", action.Info);
	const dateCreated = useActionLabelAndGuidance("DateCreated", action.Info);
	const isCommentMandatory = action.IsCommentMandatory;

	const handleOnAddAttachments = (files: File[]) => {
		files.forEach((file) => addAttachment(file));
	};

	const getErrors = (field: string) => {
		return (
			formState.errors[field] &&
			Object.values(formState.errors[field]?.types ?? {})
				.map((error) => (typeof error === "string" ? error : ""))
				.filter(Boolean)
		);
	};

	return (
		<div className="she-action-detail">
			<dl>
				<ActionReadOnlyField info={action.Info} propertyName="DateCreated">
					<div className="she-icon she-action-detail__icon">
						<Calendar />
					</div>

					{formattedDateCreated}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="DueDate">
					<div className="she-icon she-action-detail__icon">
						<Calendar />
					</div>

					{formattedDueDate}
				</ActionReadOnlyField>
			</dl>

			<ActionField
				fieldId="NoteComment"
				guidance={noteCommentField.guidance}
				guidanceIsPopup={noteCommentField.guidanceIsPopup}
				required={noteCommentField.isMandatory}
				showGuidance={noteCommentField.showGuidance}
				title={noteCommentField.label}
				validationErrors={getErrors("NoteComment")}
			>
				<Controller
					control={control}
					defaultValue={action.NoteComment}
					name="NoteComment"
					render={({ field: { ref, value, ...props } }) => (
						<TextareaAutosize
							className="she-components-text-input"
							maxLength={5000}
							ref={ref}
							required={noteCommentField.isMandatory}
							value={value || ""}
							{...props}
						/>
					)}
					rules={{
						maxLength: 5000,
						required: isCommentMandatory
							? `${t("validation:mandatoryFieldMessage")}`
							: undefined,
						validate: (value: string) => {
							if (isCommentMandatory) {
								if (!value || value.trim().length === 0) {
									return `${t("validation:mandatoryFieldMessage")}`;
								}
							}
						},
					}}
				/>
			</ActionField>

			<ActionField
				fieldId="CompleteDate"
				guidance={completedDateField.guidance}
				guidanceIsPopup={completedDateField.guidanceIsPopup}
				required={completedDateField.isMandatory}
				showGuidance={completedDateField.showGuidance}
				title={completedDateField.label}
				validationErrors={getErrors("CompleteDate")}
			>
				<Controller
					control={control}
					defaultValue={formattedDateCompleted}
					name="CompleteDate"
					render={({ field: { ...props } }) => (
						<BasicDateInput
							{...props}
							dateFormat={getDateFormat()}
							fieldId="CompleteDate"
						/>
					)}
					rules={{
						required:
							completedDateField.isMandatory &&
							`${t("validation:mandatoryFieldMessage")}`,
						validate: (value: string) => {
							if (!completedDateField.isMandatory && value === "//") {
								return true;
							} else if (value === "//") {
								return `${t("validation:mandatoryFieldMessage")}`;
							}

							const date = moment(value, getDateFormat(), true).utc(true);

							if (!date.isValid()) {
								return `${t("validation:invalidDateMessage")}`;
							}

							const isAfter = date.isSameOrAfter(
								moment(
									moment(action.DateCreated).format(getDateFormat()),
									getDateFormat(),
									true,
								).utc(true),
							);
							return (
								isAfter ||
								`${t("validation:invalidLaterDateCannotBeBeforeEarlierDate", {
									earlierDateField: dateCreated.label,
									earlierDate: moment(action.DateCreated).format(getDateFormat()),
								})}`
							);
						},
					}}
				/>
			</ActionField>

			<ActionAttachmentsList
				attachments={attachmentsToDisplay}
				readonly={false}
				removeAttachment={removeAttachment}
			/>

			<BasicFileInput
				name={`action-${action.ActionId}`}
				onAddAttachments={handleOnAddAttachments}
			/>
		</div>
	);
};
