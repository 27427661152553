import { CommonActionTypes } from "../../common/actions/types";
import { AttachmentParentType } from "../../../../models/attachments/Attachment";
import { DefaultLocalDataService } from "../../../../services/localData";
import { logException } from "../../../../services/log";
import { getAuthUser } from "../../../../helpers/AuthenticationHelpers";
import { makeDirty } from "../../questionnaire/actions/creators";
import { AttachmentActionTypes } from "./types";
import type { State } from "../../../model";
import type { Attachment } from "../../../../models/attachments/Attachment";
import type { AttachmentAction, EditAttachmentAction } from "./definitions";
import type { CommonAction } from "../../common/actions/definitions";
import type { SimpleThunkAction } from "../../../utils/thunk";

export const addAttachments = (
	attachments: Attachment[],
	shouldHideDescription: boolean,
): SimpleThunkAction<AttachmentAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: AttachmentActionTypes.Add,
				shouldHideDescription,
				attachments,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:dataRetrievalFailed",
				hasError: true,
			});
		}
	};
};

export const removeAttachment = (
	attachmentId: string,
): SimpleThunkAction<AttachmentAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: AttachmentActionTypes.Remove,
				attachmentId,
			});
			dispatch(makeDirty());
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:dataRetrievalFailed",
				hasError: true,
			});
		}
	};
};

export const cancelRecordAttachments = (): SimpleThunkAction<
	AttachmentAction | CommonAction,
	State
> => {
	return (dispatch) => {
		try {
			dispatch({
				type: AttachmentActionTypes.RecordCancel,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:dataRetrievalFailed",
				hasError: true,
			});
		}
	};
};

export const loadRecordAttachments = (
	recordId: string,
): SimpleThunkAction<AttachmentAction | CommonAction, State> => {
	return async (dispatch) => {
		let user = null;
		try {
			user = await getAuthUser();
		} catch {
			return;
		}
		const localDataService = new DefaultLocalDataService();
		const attachments: Attachment[] = await localDataService.loadRecordAttachments(
			recordId,
			user,
		);

		try {
			dispatch({
				type: AttachmentActionTypes.RecordLoad,
				attachments,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:dataRetrievalFailed",
				hasError: true,
			});
		}
	};
};

export const editAttachment = (attachments: Attachment[]): EditAttachmentAction => ({
	type: AttachmentActionTypes.Edit,
	attachments,
});

export const setListOfEditedAttachments = (attachmentIds: string[]): AttachmentAction => ({
	type: AttachmentActionTypes.ListOfEdited,
	attachmentIds,
});

export const clearOrphanedAttachmentsFromState = (): SimpleThunkAction<AttachmentAction, State> => {
	return (dispatch, getState) => {
		const state = getState();
		const toRemove = state.recordAttachments.attachments.filter(
			(a) =>
				a.parentType === AttachmentParentType.Submodule &&
				!state.questionnaire.subModuleRecords.some((s) => s.localId === a.parentGuid),
		);
		toRemove.forEach((a) => {
			dispatch({
				type: AttachmentActionTypes.Remove,
				attachmentId: a.id,
			});
		});
	};
};
