import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ConfirmationModal, FormModal } from "../../../../components/modals";
import { CreateAction } from "../CreateAction/CreateAction.component";
import { DefaultActionsService } from "../../../../../services/actions";
import { DefaultAttachmentService } from "../../../../../services/attachment";
import { fileNameToPost } from "../../../../../helpers/FileHelper";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { Loading } from "../../../../components/Loading";
import { ReactComponent as ErrorIllustration } from "../../../../assets/svg/illustrations/falling.svg";
import { useManageActionAttachments } from "../../../../utilities/UseManageActionAttachments";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { useGetActionMetadataQuery } from "../../hooks/useGetActionMetadataQuery";
import { useActionLabelAndGuidance } from "../../hooks/useActionLabelAndGuidance";
import type { Entity } from "../../../../../models/entity";

interface Match {
	params: { action: string };
}

interface CreateActionFormData {
	OrgUnitId: number | undefined | null;
	ActionHeader: string;
	ActionCategory: string;
	ActionSubCategory: string;
	ActionDetail: string;
	ForUserIds: Entity | null;
	DueDate: string;
	Priority: number;
	CompletePercent: string;
	NoteComment: string;
}
interface CreateActionDTO {
	OrgUnitId: number | undefined | null;
	ActionHeader: string;
	ActionCategory: string | null;
	ActionSubCategory: string | null;
	ActionDetail: string;
	ForUserIds: number[];
	DueDate: string;
	Priority: number;
	CompletePercent: string;
	NoteComment: string;
	AddAttachments?: { FileName: string; FileDescription: string }[];
}

interface Props {
	closeCallback: () => void;
	refetch: () => void;
}

export const CreateActionModal = ({ closeCallback, refetch }: Props) => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const customerKey = window.location.pathname.split("/")[1];
	const portalKey = window.location.pathname.split("/")[3];
	const match: Match | null = useRouteMatch("/:customerKey/p/:portalKey/actions/:action(create)");

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitError, setIsSubmitError] = useState(false);
	const [isConfirmationModalActive, setIsConfirmationModalActive] = useState(false);

	const portalPath = usePortalPath();

	const service = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	const metadata = useGetActionMetadataQuery();
	const actionHeaderFieldInfo = useActionLabelAndGuidance("ActionHeader", metadata.data);
	const actionCategoryFieldInfo = useActionLabelAndGuidance("ActionCategory", metadata.data);
	const actionSubCategoryFieldInfo = useActionLabelAndGuidance(
		"ActionSubCategory",
		metadata.data,
	);
	const actionDetailFieldInfo = useActionLabelAndGuidance("ActionDetail", metadata.data);
	const dueDateFieldInfo = useActionLabelAndGuidance("DueDate", metadata.data);
	const priorityFieldInfo = useActionLabelAndGuidance("Priority", metadata.data);
	const completePercentFieldInfo = useActionLabelAndGuidance("CompletePercent", metadata.data);
	const noteCommentFieldInfo = useActionLabelAndGuidance("NoteComment", metadata.data);

	const { handleSubmit, formState, ...methods } = useForm<CreateActionFormData>({
		mode: "onBlur",
		criteriaMode: "all",
		defaultValues: {
			OrgUnitId: null,
			ActionHeader: actionHeaderFieldInfo.defaultValue || "",
			ActionCategory: actionCategoryFieldInfo.defaultValue || "",
			ActionSubCategory: actionSubCategoryFieldInfo.defaultValue || "",
			ActionDetail: actionDetailFieldInfo.defaultValue || "",
			ForUserIds: null,
			DueDate: dueDateFieldInfo.defaultValue || "",
			Priority: parseInt(priorityFieldInfo.defaultValue || "1") || 1,
			CompletePercent: completePercentFieldInfo.defaultValue || "0",
			NoteComment: noteCommentFieldInfo.defaultValue || "",
		},
	});

	const { attachmentsToAdd, attachmentsToRemove, ...attachmentsProps } =
		useManageActionAttachments([]);

	const redirectToList = useCallback(() => {
		history.push(`${portalPath}actions`);
	}, [history, portalPath]);

	const onCancel = () => {
		if (attachmentsToAdd.length || attachmentsToRemove.length || formState.isDirty) {
			setIsConfirmationModalActive(true);
			return;
		}
		redirectToList();
		closeCallback();
	};

	const onOk = useCallback(() => {
		handleSubmit((data) => {
			const attachmentsService = new DefaultAttachmentService({
				subdomain: "ActionAttachments",
			});

			setIsSubmitting(true);

			const action: CreateActionDTO = {
				OrgUnitId: data.OrgUnitId,
				ActionHeader: data.ActionHeader,
				ActionCategory: data.ActionCategory,
				ActionSubCategory: data.ActionSubCategory,
				ActionDetail: data.ActionDetail,
				ForUserIds: data.ForUserIds ? [data.ForUserIds.id] : [],
				DueDate: data.DueDate,
				Priority: data.Priority,
				CompletePercent: data.CompletePercent,
				NoteComment: data.NoteComment,
			};

			if (action.ActionCategory === "-1") {
				action.ActionCategory = null;
			}

			if (action.ActionSubCategory === "-1") {
				action.ActionSubCategory = null;
			}

			if (attachmentsToAdd.length) {
				action.AddAttachments = attachmentsToAdd.map((attachment) => ({
					FileName: fileNameToPost(attachment.Identifier, attachment.FileName),
					FileDescription: "",
				}));
			}

			if (action.DueDate) {
				const dueDate = moment(data.DueDate, getDateFormat(), true).utc(true);
				action.DueDate = dueDate.toISOString();
			}

			attachmentsService
				.postActionAttachments(attachmentsToAdd)
				.then(() => {
					const language = switchMatchingLanguageCode(i18n.language);
					return service.createAction(customerKey, portalKey, action, language);
				})
				.then(() => {
					setIsSubmitting(false);
					refetch();
					redirectToList();
				})
				.catch(() => {
					setIsSubmitting(false);
					setIsSubmitError(true);
				});
		})();
	}, [handleSubmit, customerKey, portalKey, refetch, attachmentsToAdd, redirectToList, service]);

	return (
		<>
			<FormModal
				cancelText={t("global:cancel")}
				large
				okText={t("display:buttonSave")}
				onCancel={onCancel}
				onOk={onOk}
				show={match !== null && match.params.action === "create"}
				title={t("display:myTasks.labelCreateTask")}
				withHistory={false}
			>
				<FormProvider formState={formState} handleSubmit={handleSubmit} {...methods}>
					<CreateAction {...attachmentsProps} />
				</FormProvider>
			</FormModal>

			{isSubmitError && (
				<IllustratedThemedModal
					cancelText={t("global:ok")}
					onCancel={() => setIsSubmitError(false)}
					show={isSubmitError}
					text={t("error:failedToCreateTheTask")}
					withHistory={false}
				>
					<ErrorIllustration />
				</IllustratedThemedModal>
			)}

			{isSubmitting && <Loading withHistory={false} />}

			<ConfirmationModal
				onCancel={() => setIsConfirmationModalActive(false)}
				onConfirm={redirectToList}
				show={isConfirmationModalActive}
				text={t("display:labelAreYouSure")}
			/>
		</>
	);
};
