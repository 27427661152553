import Moment from "moment-timezone";
import i18next, { t } from "i18next";
import DOMPurify from "dompurify";
import { v4 as uuid } from "uuid";
import { AttachmentParentType } from "../../../../models/attachments/Attachment";
import { DefaultAttachmentService } from "../../../attachment";
import {
	FieldType,
	LocalFieldIds,
	LocalGroupIds,
	LocalSectionId,
	LocalSectionOrderIndex,
	QuestionnaireProgressState,
	SectionType,
	SelectFieldType,
	SubmoduleType,
} from "../../../../models/questionnaire";
import { DefaultDictionaryService } from "../../../dictionary";
import { DefaultMatrixService } from "../../../matrix";
import { PortalAuthenticationMode } from "../../../../models/portal";
import {
	mapAdditionalField,
	mapIQField,
	mapMainOrIndirectField,
	mapSubModuleField,
} from "../../../utilities/mapperInbound";
import {
	mapAdditionalFieldToAnswerDto,
	mapIQAnswerToAnswerDto,
	mapIQAuditAnswerToAnswerDto,
	mapIQBehaviouralAnswerToAnswerDto,
	mapIQBranchConditionToAnswerDto,
	mapIQChecklistAnswerToAnswerDto,
	mapIQDateAnswerToAnswerDto,
	mapIQDateTimeAnswerToAnswerDto,
	mapIQMultiSelectAnswerToAnswerDto,
	mapIQSectionToAnswerDto,
	mapIQSignatureAnswerToAnswerDto,
	mapIQSingleSelectAnswerToAnswerDto,
	mapIQTimeAnswerToAnswerDto,
	mapPortalFieldToAnswerDto,
	mapSubModuleAnswersToAnswerDto,
	mapSubModuleRecordsToAssignmentDto,
	mapSubmoduleRecordsToActionsDto,
} from "../../../utilities/mapperOutbound";
import { withSignInGet, withSignInPost } from "../../../utilities/http";
import { BaseService } from "../../../baseService";
import { DefaultLocalDataService } from "../../../localData";
import { getAuthUser } from "../../../../helpers/AuthenticationHelpers";
import { store } from "../../../../state";
import { logException } from "../../../log";
import type { Attachment } from "../../../../models/attachments/Attachment";
import type { AttachmentService } from "../../../attachment";
import type {
	BranchCondition,
	Group,
	Questionnaire,
	QuestionnaireTemplate,
	Section,
	Status,
	SubModule,
} from "../../../../models/questionnaire";
import type { DictionaryService } from "../../../dictionary";
import type { MatrixService } from "../../../matrix";
import type { Portal } from "../../../../models/portal";
import type { AuditField } from "../../../../models/fields/AuditField";
import type { BehaviouralField } from "../../../../models/fields/BehaviouralField";
import type { BooleanField } from "../../../../models/fields/BooleanField";
import type { Field } from "../../../../models/fields/Field";
import type { IQDateField } from "../../../../models/fields/IQDateField";
import type { IQDateTimeField } from "../../../../models/fields/IQDateTimeField";
import type { IQSignatureField } from "../../../../models/fields/IQSignatureField";
import type { MultiSelectField } from "../../../../models/fields/MultiSelectField";
import type { IndirectField } from "../../../../models/fields/IndirectField";
import type { OptionalSelectField } from "../../../../models/fields/OptionalSelectField";
import type { QuestionnaireService } from "../../interface";
import type { QuestionnaireState } from "../../../../state/components/questionnaire";
import type { SingleSelectField } from "../../../../models/fields/SingleSelectField";
import type { TimeField } from "../../../../models/fields/TimeField";

const HIDDEN_SUBMODULE_IDS = [LocalSectionId.Submission];

export class DefaultQuestionnaireService extends BaseService implements QuestionnaireService {
	// Get the template from assure (Maxwell service)
	public async getTemplate(
		templateId: number,
		portalKey: string,
	): Promise<QuestionnaireTemplate> {
		const userLanguage = i18next.language;
		const endpoint = await this.getEndpointUrl();
		const serviceUrl = `${endpoint}?Id=${templateId}&userLanguage=${userLanguage}`;
		const response = await withSignInGet<any>(serviceUrl, await this.getAuthHeaders());
		const template = response.parsedBody;

		// eslint-disable-next-line no-useless-catch
		try {
			const groups: Group[] = [];
			const fields: Field[] = [];
			const branchConditions: BranchCondition[] = [];

			const questionnaire: Questionnaire = {
				id: uuid(),
				templateId: template.PortalQuestionnaire.Id,
				portalKey,
				name: template.PortalQuestionnaire.MapName,
				maxAttachments: template.PortalQuestionnaire.HasAttachments
					? template.PortalQuestionnaire.MaxUploads
					: 0,
				allowActions: template.PortalQuestionnaire.AllowActions,
				browserTimeZone: Moment.tz.guess(false),
				language: template.PortalQuestionnaire.Language,
				orgUnitCaption: template.PortalQuestionnaire.OrgUnitCaption,
				uploadGuidance: template.PortalQuestionnaire.UploadGuidance,
			};

			const status: Status = {
				isValidated: false,
				isSubmittingInProgress: false,
				errors: [],
				displayValidationMessage: false,
				questionnaireState: QuestionnaireProgressState.InProgress,
			};

			const sections: Section[] = [
				{
					questionnaireId: questionnaire.id,
					guid: uuid(),
					id: 0,
					name: questionnaire.name,
					type: SectionType.Main,
					orderIndex: 0,
					isVisible: true,
					guidanceIsPopup: false,
				},
			];

			const subModules: SubModule[] = await this.AddSubModules(
				template,
				questionnaire,
				sections,
				fields,
			);

			await this.AddPortalQuestionnaireFields(
				template,
				questionnaire,
				sections,
				groups,
				fields,
			);

			DefaultQuestionnaireService.AddOrgUnitControl(
				questionnaire,
				sections,
				groups,
				fields,
				template,
			);

			if (
				(template.PortalQuestionnaire.IsConfidentialityEqsTemplateDriven &&
					template.EqsTemplate &&
					template.EqsTemplate.IsConfidentialityEnabled) ||
				(!template.PortalQuestionnaire.IsConfidentialityEqsTemplateDriven &&
					template.PortalQuestionnaire.IsConfidentialityEnabled)
			) {
				DefaultQuestionnaireService.AddEnhancedConfidentiality(
					template,
					questionnaire,
					sections,
					groups,
					fields,
				);
			}

			if (!template.PortalQuestionnaire.IsAnonymous) {
				DefaultQuestionnaireService.AddDetailsFields(
					template,
					questionnaire,
					sections,
					groups,
					fields,
				);
			}

			if (template.EqsTemplate) {
				questionnaire.iQTemplateId = template.EqsTemplate.Id;
				questionnaire.notApplicableComments =
					template.EqsTemplate.NotApplicableCommentsOption;

				let startIndex = 1;
				if (
					template.EqsTemplate.CustomFieldTemplates &&
					template.EqsTemplate.CustomFieldTemplates.length
				) {
					this.AddAdditionalFields(template, questionnaire, sections, groups, fields);
					startIndex++;
				}

				if (
					template.EqsTemplate &&
					template.EqsTemplate.ShowGuidance &&
					(template.EqsTemplate.GuidanceText || template.EqsTemplate.MediaGuidance)
				) {
					DefaultQuestionnaireService.AddIQTemplateGuidanceSection(
						template,
						questionnaire,
						sections,
						startIndex,
					);
				}

				if (
					template.EqsTemplate.SectionTemplates &&
					template.EqsTemplate.SectionTemplates.length
				) {
					this.AddIQTemplateSections(
						template,
						questionnaire,
						sections,
						groups,
						fields,
						startIndex,
					);
				}

				if (
					template.EqsTemplate.BranchConditionTemplates &&
					template.EqsTemplate.BranchConditionTemplates.length
				) {
					this.AddIQTemplateBranchConditions(
						template,
						questionnaire,
						sections,
						fields,
						branchConditions,
					);
				}
			}

			if (
				template.PortalQuestionnaire.IsAssignmentEnabled &&
				store.getState().portal.portals[0] &&
				store.getState().portal.portals[0].authenticationMode !==
					PortalAuthenticationMode.Public &&
				(await getAuthUser())
			) {
				sections.push({
					questionnaireId: questionnaire.id,
					guid: uuid(),
					id: LocalSectionId.Submission,
					name: t("display:labelSubmission"),
					type: SectionType.Submission,
					isVisible: true,
					orderIndex: LocalSectionOrderIndex.Submission,
					guidanceIsPopup: false,
					guidance: template.PortalQuestionnaire.GuidanceText,
				});

				groups.push({
					orderIndex: 1,
					name: t("display:labelSubmission"),
					questionnaireId: questionnaire.id,
					sectionId: LocalSectionId.Submission,
					id: LocalGroupIds.Submission,
				});
			}

			if (template.PortalQuestionnaire.AllowActions) {
				sections.push({
					questionnaireId: questionnaire.id,
					guid: uuid(),
					id: LocalSectionId.Actions,
					name: t("display:labelActions"),
					type: SectionType.Actions,
					isVisible: true,
					orderIndex: LocalSectionOrderIndex.Actions,
					guidanceIsPopup: false,
				});
			}

			if (template.PortalQuestionnaire.HasAttachments) {
				sections.push({
					questionnaireId: questionnaire.id,
					guid: uuid(),
					id: LocalSectionId.Attachments,
					name: t("display:labelAttachments"),
					type: SectionType.Attachments,
					isVisible: true,
					orderIndex: LocalSectionOrderIndex.Attachments,
					guidanceIsPopup: false,
				});
			}

			return {
				questionnaire,
				status,
				sections: sections.sort((a, b): number => {
					if (a.orderIndex < b.orderIndex) {
						return -1;
					}
					if (a.orderIndex > b.orderIndex) {
						return 1;
					}
					return 0;
				}),
				groups,
				fields,
				subModules,
				branchConditions,
				dictionaries: [],
				matrixes: [],
				subModuleRecords: [],
				notes: [],
			};
		} catch (e) {
			throw e;
		}
	}

	public async saveTemplateForOffline(templateId: number, portal: Portal): Promise<void> {
		const localDataService = new DefaultLocalDataService();
		const dictionaryService: DictionaryService = new DefaultDictionaryService({
			subdomain: "Dictionary",
		});
		const matrixService: MatrixService = new DefaultMatrixService({
			subdomain: "Matrix",
		});

		await this.getTemplate(templateId, portal.key).then(async (template) => {
			const templateToSave = template;

			const orgUnitField = template.fields.find((f) => f.id === LocalFieldIds.OrgUnit);
			if (orgUnitField && orgUnitField.type === FieldType.OrgUnit) {
				orgUnitField.value = !orgUnitField.isForced
					? portal.defaultOrgUnitId || portal.maskedOrgUnitId || orgUnitField.defaultValue
					: undefined;
			}

			templateToSave.dictionaries = (
				await dictionaryService.getDictionaries(template.questionnaire.templateId)
			).dictionaries;
			templateToSave.matrixes = (await matrixService.getMatrices(template.fields)).matrixList;

			await localDataService.saveTemplate(templateToSave);
		});
	}

	public async submitQuestionnaire(
		questionnaire: QuestionnaireState,
		attachments: Attachment[],
		authToken?: string,
	): Promise<{ isSaved: boolean; errors: string[] }> {
		// eslint-disable-next-line no-useless-catch
		try {
			const attachmentsResponse = await this.SubmitAttachments(
				questionnaire,
				attachments,
			).catch((e) => {
				logException(e);
				return e;
			});

			if (attachmentsResponse !== true) {
				logException(new Error("Couldn't uplad attachments"));
				return { isSaved: false, errors: attachmentsResponse };
			}

			let headers = await this.getAuthHeadersInit();
			if (authToken) {
				headers = { ...headers, Authorization: `Bearer ${authToken}` };
			}

			return await withSignInPost<any>(
				await this.getEndpointUrl(),
				{ headers },
				await this.buildSubmitData(questionnaire, attachments),
			)
				.then((response) => {
					if (response.parsedBody?.IsSaved === true) {
						return { isSaved: true, errors: [] };
					}
					logException({
						error: "Server responded with success status code but questionnaire wasn't saved",
						customData: {
							status: response.status,
							body: response.parsedBody,
						},
					});
					return {
						isSaved: false,
						errors: [
							"Server responded with success status code but questionnaire wasn't saved",
						],
					};
				})
				.catch((e) => {
					logException(e);
					return { isSaved: false, errors: e };
				});
		} catch (e) {
			throw e;
		}
	}

	private async SubmitAttachments(
		questionnaire: QuestionnaireState,
		attachments: Attachment[],
	): Promise<any> {
		const attachmentService: AttachmentService = new DefaultAttachmentService({
			subdomain: "Attachment",
		});
		if (attachments && attachments.length > 0) {
			for await (const attachment of attachments) {
				let fieldId: number | undefined;
				let templateId: number | undefined =
					questionnaire.questionnaire && questionnaire.questionnaire.templateId;

				if (attachment.parentType === AttachmentParentType.Field && attachment.parentGuid) {
					const field = questionnaire.fields.find(
						(f) => f.guid === attachment.parentGuid,
					);
					fieldId = field && field.id;
					templateId =
						questionnaire.questionnaire && questionnaire.questionnaire.iQTemplateId;
				}

				await attachmentService
					.postAttachment(attachment, templateId, fieldId)
					.catch((e) => {
						throw e;
					});
			}
		}

		return true;
	}

	private async buildSubmitData(
		state: QuestionnaireState,
		attachments: Attachment[],
	): Promise<any> {
		const questionnaire = state.questionnaire!;
		const sections = state.sections.filter((s) => s.questionnaireId === questionnaire.id);
		const groups = state.groups.filter((g) => g.questionnaireId === questionnaire.id);
		const fields = state.fields.filter((f) => f.questionnaireId === questionnaire.id);
		const subModuleFields = state.fields.filter(
			(f) => f.questionnaireId === questionnaire.id && f.subModuleId,
		);

		const additionalSections = sections.filter((s) => s.type === SectionType.Additional);
		const iQSections = sections.filter((s) => s.type === SectionType.iQ);
		const subModules = state.subModules;
		const subModuleRecords = state.subModuleRecords.filter(
			(sm) =>
				sm.parentId !== LocalSectionId.Actions && sm.subModuleId !== LocalSectionId.Actions,
		);
		const branchConditions = state.branchConditions.filter(
			(bc) => bc.questionnaireId === questionnaire.id,
		);
		const recordAttachments = attachments.filter(
			(a) => a.recordId === questionnaire.id && a.parentType === AttachmentParentType.Record,
		);
		const notes = state.notes.filter((n) => n.questionnaireId === questionnaire.id);
		const actions = state.subModuleRecords.filter(
			(sm) => sm.parentId === LocalSectionId.Actions,
		);
		const iQactions = state.subModuleRecords.filter(
			(sm) => sm.parentGuid && sm.subModuleId === LocalSectionId.Actions,
		);
		const allActions = state.subModuleRecords.filter(
			(sm) => sm.subModuleId === LocalSectionId.Actions,
		);
		const assignmentFields = state.fields.filter(
			(field) => field.sectionId === LocalSectionId.Submission,
		);

		const data: any = {
			PortalQuestionnaireRecord: {
				MapId: questionnaire.templateId,
				PortalKey: questionnaire.portalKey,
				IpAddress: undefined,
				IsHumanResources: false,
				IsOverrideHumanResources: false,
				StoreAnswers: this.getApplicableFieldsAnswers(fields, sections[0].id),
				StoreSubModules: mapSubModuleAnswersToAnswerDto(
					subModules,
					subModuleRecords,
					subModuleFields,
					allActions,
					attachments,
				),
				AttachmentsWithDescription: recordAttachments.map((a) => {
					return { fileName: a.fileName, fileDescription: a.description };
				}),
				browserTimeZone: questionnaire.browserTimeZone,
				StoreActions: mapSubmoduleRecordsToActionsDto(actions, attachments),
				StoreAssignment: mapSubModuleRecordsToAssignmentDto(assignmentFields),
			},
			EqsQuestionnaire: {
				...(questionnaire.iQTemplateId && {
					Id: uuid(),
					DateModified: new Date(),
					QuestionnaireTemplateId: questionnaire.iQTemplateId,
					DateCreated: new Date(),
					DateStarted: new Date(),
					DateCompleted: new Date(),
					CreatedVersion: "",
				}),
				BranchConditions: [],
				AuditAnswers: [],
				BehaviouralAnswers: [],
				ChecklistAnswers: [],
				SignatureAnswers: [],
				DateTimeAnswers: [],
				DateAnswers: [],
				TimeAnswers: [],
				QuestionNotes: [],
				QuestionActions: [],
			},
		};

		data.EqsQuestionnaire.QuestionNotes = notes.map((n) => {
			return { NoteText: n.value, QuestionId: n.fieldGuid };
		});

		data.EqsQuestionnaire.Fields = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.sectionId &&
					additionalSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) =>
				DefaultQuestionnaireService.GetAdditionalFieldAnswers(f, data.EqsQuestionnaire.Id),
			);

		data.EqsQuestionnaire.Sections = iQSections.map((s) =>
			mapIQSectionToAnswerDto(s, data.EqsQuestionnaire.Id),
		);

		data.EqsQuestionnaire.QuestionActions = mapSubmoduleRecordsToActionsDto(
			iQactions,
			attachments,
		);

		data.EqsQuestionnaire.NumericAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Number &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQAnswerToAnswerDto(f, section, fieldAttachments);
			});

		data.EqsQuestionnaire.SignatureAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.IQSignature &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQSignatureAnswerToAnswerDto(
					f as IQSignatureField,
					section,
					fieldAttachments,
				);
			});

		data.EqsQuestionnaire.TextAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Text &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQAnswerToAnswerDto(f, section, fieldAttachments);
			});

		data.EqsQuestionnaire.SingleSelectAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Select &&
					f.selectType === SelectFieldType.SingleSelect &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				f = f as SingleSelectField;
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQSingleSelectAnswerToAnswerDto(f, section, fieldAttachments);
			});

		data.EqsQuestionnaire.MultiSelectAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Select &&
					f.selectType === SelectFieldType.MultiSelect &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				f = f as MultiSelectField;
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQMultiSelectAnswerToAnswerDto(f, section, fieldAttachments);
			});

		data.EqsQuestionnaire.AuditAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Audit &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQAuditAnswerToAnswerDto(f as AuditField, section, fieldAttachments);
			});

		data.EqsQuestionnaire.ChecklistAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Boolean &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				f = f as BooleanField;
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQChecklistAnswerToAnswerDto(f, section, fieldAttachments);
			});

		data.EqsQuestionnaire.BehaviouralAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Behavioural &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQBehaviouralAnswerToAnswerDto(
					f as BehaviouralField,
					section,
					fieldAttachments,
				);
			});

		data.EqsQuestionnaire.DateTimeAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.IQDateTime &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQDateTimeAnswerToAnswerDto(
					f as IQDateTimeField,
					section,
					fieldAttachments,
				);
			});

		data.EqsQuestionnaire.DateAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.IQDate &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQDateAnswerToAnswerDto(f as IQDateField, section, fieldAttachments);
			});

		data.EqsQuestionnaire.TimeAnswers = fields
			.filter(
				(f) =>
					f.groupId &&
					f.id >= 0 &&
					f.type === FieldType.Time &&
					f.sectionId &&
					iQSections.map((s) => s.id).includes(f.sectionId),
			)
			.map((f) => {
				const parentGroup = groups.find((g) => g.id === f.groupId);
				const section = sections.find(
					(s) => parentGroup && s.id && s.id === parentGroup.sectionId,
				);
				const fieldAttachments = attachments.filter(
					(a) => a.parentGuid && a.parentGuid === f.guid,
				);
				return mapIQTimeAnswerToAnswerDto(f as TimeField, section, fieldAttachments);
			});

		data.EqsQuestionnaire.BranchConditions = branchConditions.map((bc) =>
			mapIQBranchConditionToAnswerDto(bc),
		);

		const orgUnitField = fields.find((f) => f.id === LocalFieldIds.OrgUnit);
		if (orgUnitField) {
			data.PortalQuestionnaireRecord.OrgUnitId = orgUnitField.value;
		}

		const detailsGroup = groups.find((g) => g.id === LocalGroupIds.Details);
		if (detailsGroup) {
			const nameField = fields.find((f) => f.id === LocalFieldIds.Name);
			if (nameField) {
				data.PortalQuestionnaireRecord.Name = nameField.value;
			}

			const phoneField = fields.find((f) => f.id === LocalFieldIds.Phone);
			if (phoneField) {
				data.PortalQuestionnaireRecord.Phone = phoneField.value;
			}

			const emailField = fields.find((f) => f.id === LocalFieldIds.Email);
			if (emailField) {
				data.PortalQuestionnaireRecord.Email = emailField.value;
			}

			const sentSubmitEmailField = fields.find((f) => f.id === LocalFieldIds.EmailCopy);
			if (sentSubmitEmailField) {
				data.PortalQuestionnaireRecord.IsSubmitEmailSent = sentSubmitEmailField.value;
			}

			const additionalEmails = fields.find((f) => f.id === LocalFieldIds.AdditionalEmails);
			if (additionalEmails) {
				data.PortalQuestionnaireRecord.AdditionalEmail = additionalEmails.value;
			}
		}

		const ConfidentialityGroup = groups.find((g) => g.id === LocalGroupIds.Confidentiality);
		if (ConfidentialityGroup) {
			const confidentialityField = fields.find((f) => f.id === LocalFieldIds.Confidentiality);
			if (confidentialityField) {
				data.PortalQuestionnaireRecord.IsHumanResources = confidentialityField.value === 1;
				data.PortalQuestionnaireRecord.IsOverrideHumanResources =
					confidentialityField.value === 2;
			}
		}

		return data;
	}

	private static AddOrgUnitControl(
		questionnaire: any,
		sections: Section[],
		groups: Group[],
		fields: Field[],
		template: any,
	): void {
		if (!template.PortalQuestionnaire.IsOrgUnitHidden) {
			groups.unshift({
				questionnaireId: questionnaire.id,
				sectionId: sections[0].id,
				id: LocalGroupIds.OrgUnit,
				name: template.PortalQuestionnaire.OrgUnitCaption || t("display:labelOrgUnit"),
				orderIndex: -2,
			});
		}

		fields.unshift({
			questionnaireId: questionnaire.id,
			sectionId: sections[0].id,
			groupId: LocalGroupIds.OrgUnit,
			guid: uuid(),
			id: LocalFieldIds.OrgUnit,
			name: template.PortalQuestionnaire.OrgUnitCaption || t("display:labelOrgUnit"),
			type: FieldType.OrgUnit,
			isMandatory: true,
			comments: {
				isEnabled: false,
				isNotApplicableCommentsEnabled: false,
			},
			allowNotApplicable: false,
			isNotApplicable: false,
			notesEnabled: false,
			orderIndex: 0,
			isIq: false,
			isConfidential: false,
			validationMessage: [],
			actionValidationMessage: [],
			attachmentValidationMessage: [],
			guidanceIsPopup: template.PortalQuestionnaire.OrgUnitGuidanceIsPopup,
			isUpdated: true,
			defaultValue: template.PortalQuestionnaire.DefaultOrgUnitId,
			hidden: template.PortalQuestionnaire.IsOrgUnitHidden || false,
			isFixed: template.PortalQuestionnaire.OrgUnitFixed || false,
			isForced: template.PortalQuestionnaire.ForceOrgUnitSelection || false,
			showGuidance: template.PortalQuestionnaire.OrgUnitGuidance !== null,
			guidance: template.PortalQuestionnaire.OrgUnitGuidance,
			attachmentsEnabled: false,
			actionsEnabled: false,
			isScannable: false,
		});
	}

	private AddAdditionalFields(
		template: any,
		questionnaire: any,
		sections: Section[],
		groups: Group[],
		fields: Field[],
	): void {
		const additionalFieldsSectionId = 1;

		sections.push({
			questionnaireId: questionnaire.id,
			guid: uuid(),
			id: additionalFieldsSectionId,
			name: t("display:labelAdditionalFields"),
			type: SectionType.Additional,
			orderIndex: 1,
			isVisible: true,
			guidanceIsPopup: false,
		});

		groups.push({
			questionnaireId: questionnaire.id,
			sectionId: additionalFieldsSectionId,
			id: uuid(),
			name: t("display:labelAdditionalFields"),
			orderIndex: 0,
		});

		fields.push(
			...template.EqsTemplate.CustomFieldTemplates.map((f: any) =>
				mapAdditionalField(
					f,
					questionnaire.id,
					groups[groups.length - 1].id,
					additionalFieldsSectionId,
				),
			),
		);
	}

	private AddIQTemplateSections(
		template: any,
		questionnaire: any,
		sections: Section[],
		groups: Group[],
		fields: Field[],
		startIndex: number,
	): void {
		template.EqsTemplate.SectionTemplates.forEach((st: any) => {
			sections.push({
				questionnaireId: questionnaire.id,
				guid: uuid(),
				id: st.Id,
				name: st.Name,
				type: SectionType.iQ,
				// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
				orderIndex: st.OrderIndex + startIndex,
				isVisible: st.IsVisible,
				guidanceIsPopup: st.GuidanceIsPopup,
				guidance: st.GuidanceText,
				mediaGuidance: DOMPurify.sanitize(st.MediaGuidance, {
					ADD_TAGS: ["iframe"],
					ADD_ATTR: ["style", "allow", "allowfullscreen", "src"],
				}),
			});

			groups.push({
				questionnaireId: questionnaire.id,
				sectionId: st.Id,
				id: uuid(),
				name: st.Name,
				orderIndex: st.OrderIndex,
			});

			fields.push(
				...template.EqsTemplate.NumericQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.TextQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.SingleSelectQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.MultiSelectQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.AuditQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.ChecklistQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.SignatureQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.BehaviouralQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.DateTimeQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.DateQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
			fields.push(
				...template.EqsTemplate.TimeQuestionTemplates.filter(
					(qt: any) => qt.QuestionnaireSectionTemplateId === st.Id,
				).map((qt: any) =>
					mapIQField(
						qt,
						questionnaire.id,
						groups[groups.length - 1].id,
						template.EqsTemplate,
						st.Id,
					),
				),
			);
		});
	}

	private AddIQTemplateBranchConditions(
		template: any,
		questionnaire: any,
		sections: Section[],
		fields: Field[],
		branchConditions: BranchCondition[],
	): void {
		template.EqsTemplate.BranchConditionTemplates.forEach((bc: any) => {
			const field = fields.find((f) => f.isIq && f.id === bc.QuestionTemplateId);
			if (!field) {
				throw new Error(
					`Unable to find the field for Question Template ID :${bc.QuestionTemplateId}`,
				);
			}
			const section = sections.find((s) => s.id === bc.QuestionnaireSectionTemplateId);
			if (!section) {
				throw new Error(
					`Unable to find the Section for Question Section Template ID :${bc.QuestionnaireSectionTemplateId}`,
				);
			}

			branchConditions.push({
				id: bc.Id,
				questionnaireTemplateId: bc.QuestionnaireTemplateId,
				questionnaireSectionTemplateId: bc.QuestionnaireSectionTemplateId,
				questionTemplateId: bc.QuestionTemplateId,
				OrderIndex: bc.OrderIndex,
				Value: bc.Value,
				isValid: bc.IsValid,
				comments: bc.Comments,
				alertMessage: bc.AlertMessage,
				shownSectionsIds: bc.ShownSectionsIds,
				ResponseType: bc.ResponseType,

				/// /Trigger Support
				textTriggerValues: bc.TextTriggerValues,
				selectiveTriggerIds: bc.SelectiveTriggerIds,
				numericRangeMin: bc.NumericRangeMin,
				numericRangeMax: bc.NumericRangeMax,

				/// /Branch Template
				guid: uuid(),
				questionnaireId: questionnaire.id,
				questionAnswerId: field.guid,
				questionnaireSectionId: section.guid,
				istriggered: false,
			});
		});
	}

	private static AddIQTemplateGuidanceSection(
		template: any,
		questionnaire: any,
		sections: Section[],
		startIndex: number,
	): void {
		sections.push({
			questionnaireId: questionnaire.id,
			guid: uuid(),
			id: 2345,
			name: t("display:labelIqTemplateGuidance"),
			type: SectionType.Guidance,
			isVisible: true,
			orderIndex: startIndex,
			guidanceIsPopup: template.EqsTemplate.GuidanceIsPopup,
			guidance: template.EqsTemplate.GuidanceText,
			mediaGuidance: DOMPurify.sanitize(template.EqsTemplate.MediaGuidance, {
				ADD_TAGS: ["iframe"],
				ADD_ATTR: ["style", "allow", "allowfullscreen", "src"],
			}),
		});
	}

	private static AddDetailsFields(
		template: any,
		questionnaire: any,
		sections: Section[],
		groups: Group[],
		fields: Field[],
	): void {
		groups.push({
			questionnaireId: questionnaire.id,
			sectionId: sections[0].id,
			id: LocalGroupIds.Details,
			name: t("display:labelYourDetails"),
			orderIndex: 1000,
		});

		fields.push({
			questionnaireId: questionnaire.id,
			groupId: LocalGroupIds.Details,
			guid: uuid(),
			id: LocalFieldIds.Name,
			sectionId: sections[0].id,
			name: template.PortalQuestionnaire.YourDetailsNameCaption || t("display:labelName"),
			type: FieldType.Text,
			isMandatory: template.PortalQuestionnaire.YourDetailsNameIsMandatory,
			comments: {
				isEnabled: false,
				isNotApplicableCommentsEnabled: false,
			},
			allowNotApplicable: false,
			isNotApplicable: false,
			notesEnabled: false,
			hidden: false,
			orderIndex: 0,
			isIq: false,
			isConfidential: false,
			validationMessage: [],
			actionValidationMessage: [],
			attachmentValidationMessage: [],
			guidanceIsPopup: template.PortalQuestionnaire.YourDetailsNameGuidanceIsPopUp,
			isUpdated: false,
			showGuidance: template.PortalQuestionnaire.YourDetailsNameGuidance !== null,
			guidance: template.PortalQuestionnaire.YourDetailsNameGuidance,
			maxLength: 75,
			attachmentsEnabled: false,
			actionsEnabled: false,
			defaultValue: template.PortalQuestionnaire.YourDetailsNameDefaultValue,
			value: template.PortalQuestionnaire.YourDetailsNameDefaultValue,
			isScannable: false,
		});

		fields.push({
			questionnaireId: questionnaire.id,
			groupId: LocalGroupIds.Details,
			guid: uuid(),
			id: LocalFieldIds.Phone,
			sectionId: sections[0].id,
			name: template.PortalQuestionnaire.YourDetailsNumberCaption || t("display:labelPhone"),
			type: FieldType.Text,
			isMandatory: template.PortalQuestionnaire.IsPhoneNoMandatory,
			comments: {
				isEnabled: false,
				isNotApplicableCommentsEnabled: false,
			},
			allowNotApplicable: false,
			isNotApplicable: false,
			notesEnabled: false,
			hidden: false,
			orderIndex: 1,
			isIq: false,
			isConfidential: false,
			validationMessage: [],
			actionValidationMessage: [],
			attachmentValidationMessage: [],
			guidanceIsPopup: template.PortalQuestionnaire.YourDetailsNumberGuidanceIsPopUp,
			isUpdated: false,
			showGuidance: template.PortalQuestionnaire.YourDetailsNumberGuidance !== null,
			guidance: template.PortalQuestionnaire.YourDetailsNumberGuidance,
			maxLength: 30,
			attachmentsEnabled: false,
			actionsEnabled: false,
			defaultValue: template.PortalQuestionnaire.YourDetailsNumberDefaultValue,
			value: template.PortalQuestionnaire.YourDetailsNumberDefaultValue,
			isScannable: false,
		});

		fields.push({
			questionnaireId: questionnaire.id,
			groupId: LocalGroupIds.Details,
			guid: uuid(),
			id: LocalFieldIds.Email,
			sectionId: sections[0].id,
			name: template.PortalQuestionnaire.YourDetailsEmailCaption || t("display:labelEmail"),
			type: FieldType.Email,
			isMandatory: template.PortalQuestionnaire.YourDetailsEmailIsMandatory,
			comments: {
				isEnabled: false,
				isNotApplicableCommentsEnabled: false,
			},
			allowNotApplicable: false,
			isNotApplicable: false,
			notesEnabled: false,
			hidden: false,
			orderIndex: 2,
			isIq: false,
			isConfidential: false,
			validationMessage: [],
			actionValidationMessage: [],
			attachmentValidationMessage: [],
			guidanceIsPopup: template.PortalQuestionnaire.YourDetailsEmailGuidanceIsPopUp,
			isUpdated: false,
			showGuidance: template.PortalQuestionnaire.YourDetailsEmailGuidance !== null,
			guidance: template.PortalQuestionnaire.YourDetailsEmailGuidance,
			maxLength: 255,
			attachmentsEnabled: false,
			rows: 1,
			actionsEnabled: false,
			defaultValue: template.PortalQuestionnaire.YourDetailsEmailDefaultValue,
			value: template.PortalQuestionnaire.YourDetailsEmailDefaultValue,
			isScannable: false,
		});

		fields.push({
			questionnaireId: questionnaire.id,
			groupId: LocalGroupIds.Details,
			guid: uuid(),
			id: LocalFieldIds.EmailCopy,
			name:
				template.PortalQuestionnaire.YourDetailsEmailCopyCaption ||
				t("display:labelEmailCopy"),
			type: FieldType.Boolean,
			sectionId: sections[0].id,
			isMandatory: template.PortalQuestionnaire.YourDetailsEmailCopyIsMandatory,
			comments: {
				isEnabled: false,
				isNotApplicableCommentsEnabled: false,
			},
			allowNotApplicable: false,
			isNotApplicable: false,
			notesEnabled: false,
			hidden: false,
			orderIndex: 3,
			isIq: false,
			isConfidential: false,
			validationMessage: [],
			actionValidationMessage: [],
			attachmentValidationMessage: [],
			guidanceIsPopup: template.PortalQuestionnaire.YourDetailsEmailCopyGuidanceIsPopUp,
			isUpdated: false,
			showGuidance: template.PortalQuestionnaire.YourDetailsEmailCopyGuidance !== null,
			guidance: template.PortalQuestionnaire.YourDetailsEmailCopyGuidance,
			attachmentsEnabled: false,
			actionsEnabled: false,
			defaultValue:
				template.PortalQuestionnaire.YourDetailsEmailCopyDefaultValue !== null
					? template.PortalQuestionnaire.YourDetailsEmailCopyDefaultValue.toLowerCase() ===
					  "true"
					: undefined,
			value:
				template.PortalQuestionnaire.YourDetailsEmailCopyDefaultValue !== null
					? template.PortalQuestionnaire.YourDetailsEmailCopyDefaultValue.toLowerCase() ===
					  "true"
					: undefined,
			isScannable: false,
		});

		if (template.PortalQuestionnaire.ShowAdditionalEmails) {
			fields.push({
				questionnaireId: questionnaire.id,
				groupId: LocalGroupIds.Details,
				guid: uuid(),
				id: LocalFieldIds.AdditionalEmails,
				sectionId: sections[0].id,
				name: template.PortalQuestionnaire.ShowAdditionalEmailsCaption,
				type: FieldType.Email,
				isMandatory: false,
				comments: {
					isEnabled: false,
					isNotApplicableCommentsEnabled: false,
				},
				allowNotApplicable: false,
				isNotApplicable: false,
				notesEnabled: false,
				hidden: false,
				orderIndex: 4,
				isIq: false,
				isConfidential: false,
				validationMessage: [],
				actionValidationMessage: [],
				attachmentValidationMessage: [],
				guidanceIsPopup: false,
				isUpdated: false,
				showGuidance: true,
				guidance: template.PortalQuestionnaire.ShowAdditionalEmailsGuidance,
				maxLength: 1000,
				rows: 6,
				additionalEmails: true,
				additionalEmailsRegx: template.PortalQuestionnaire.ShowAdditionalEmailsRegx,
				attachmentsEnabled: false,
				actionsEnabled: false,
				isScannable: false,
			});
		}
	}

	private async AddSubModules(
		template: any,
		questionnaire: any,
		sections: Section[],
		fields: Field[],
	): Promise<SubModule[]> {
		let subModules: SubModule[] = (
			template.PortalQuestionnaire.QuestionnaireSubModules as any[]
		).map((sm: any, i: number) => {
			switch (sm.Id) {
				case LocalSectionId.Actions:
					return {
						questionnaireId: questionnaire.id,
						sectionId: LocalSectionId.Actions,
						type: SubmoduleType.Actions,
						index: i,
						id: sm.Id,
						orderIndex: sm.OrderIndex,
						name: t("display:labelRecordActions"),
						headers: [],
						values: [],
						singleRecordName: t("display:labelAction"),
						emptyListMessage: t("display:labelNoActionsMessage"),
						hideHeader: true,
						allowAttachments: !!questionnaire.maxAttachments,
					};
				default:
					return {
						questionnaireId: questionnaire.id,
						sectionId: sections[0].id,
						type: SubmoduleType.Main,
						index: i,
						id: sm.Id,
						orderIndex: sm.OrderIndex,
						name: sm.HeaderText,
						headers: [],
						values: [],
						allowActions: sm.AllowActions && questionnaire.allowActions,
						allowAttachments: sm.AllowAttachments && !!questionnaire.maxAttachments,
					};
			}
		});

		subModules.forEach((subModule) => {
			const questionnaireSubModule =
				template.PortalQuestionnaire.QuestionnaireSubModules[subModule.index];
			const questionnaireSubModuleFields = questionnaireSubModule.SubModuleFields;

			questionnaireSubModuleFields.forEach(async (questionnaireSubModuleField: any) => {
				const mappedField = await mapSubModuleField(
					questionnaireSubModuleField,
					questionnaire.id,
					subModule.id,
				);

				fields.push(mappedField);

				if (mappedField.type === FieldType.PPE) {
					subModule.type = SubmoduleType.PPE;
				}

				if (questionnaireSubModuleField.ShowInTable) {
					subModule.headers.push({
						id: mappedField.id,
						value: mappedField.name,
						authenticatedOnly: mappedField.authenticatedOnly,
					});
				}
			});
		});

		subModules = subModules.filter((subModule) => !HIDDEN_SUBMODULE_IDS.includes(subModule.id));

		return subModules;
	}

	private async AddPortalQuestionnaireFields(
		template: any,
		questionnaire: any,
		sections: Section[],
		groups: Group[],
		fields: Field[],
	): Promise<any> {
		await template.PortalQuestionnaire.Sections.reduce(
			(accPromise: Promise<any>, mepSection: any) => {
				const gId = uuid();

				groups.push({
					questionnaireId: questionnaire.id,
					sectionId: sections[0].id,
					id: gId,
					name: mepSection.HeaderText,
					orderIndex: mepSection.OrderIndex,
				});

				return accPromise.then(async () => {
					await mepSection.Fields.reduce(async (aPromise: Promise<any>, field: any) => {
						return aPromise.then(async () => {
							fields.push(
								await mapMainOrIndirectField(
									field,
									questionnaire.id,
									gId,
									sections[0].id,
								),
							);
						});
					}, Promise.resolve());
				});
			},
			Promise.resolve(),
		);
	}

	private static GetAdditionalFieldAnswers(field: Field, questionnaireId: any): any {
		return {
			Id: uuid(),
			DateModified: new Date(),
			QuestionnaireId: questionnaireId,
			QuestionnaireTemplateFieldId: field.id,
			Value: mapAdditionalFieldToAnswerDto(field),
		};
	}

	private static AddEnhancedConfidentiality(
		template: any,
		questionnaire: any,
		sections: Section[],
		groups: Group[],
		fields: Field[],
	): void {
		groups.push({
			questionnaireId: questionnaire.id,
			sectionId: sections[0].id,
			id: LocalGroupIds.Confidentiality,
			name: t("display:labelConfidentiality"),
			orderIndex: -1,
		});

		const HumanResourcesIsDefaulted =
			(!template.PortalQuestionnaire.IsConfidentialityEqsTemplateDriven &&
				template.PortalQuestionnaire.IsForHumanResourcesUser) ||
			(template.PortalQuestionnaire.IsConfidentialityEqsTemplateDriven &&
				template.EqsTemplate &&
				template.EqsTemplate.IsForHumanResourcesUser)
				? 1
				: undefined;

		fields.push({
			questionnaireId: questionnaire.id,
			groupId: LocalGroupIds.Confidentiality,
			guid: uuid(),
			id: LocalFieldIds.Confidentiality,
			sectionId: sections[0].id,
			name: t("display:labelSectionConfidentiality"),
			isMandatory: HumanResourcesIsDefaulted === 1, // This is only mandatory if the default value is populated
			comments: {
				isEnabled: false,
				isNotApplicableCommentsEnabled: false,
			},
			allowNotApplicable: false,
			isNotApplicable: false,
			notesEnabled: false,
			hidden: false,
			orderIndex: 0,
			isIq: false,
			isConfidential: false,
			validationMessage: [],
			actionValidationMessage: [],
			attachmentValidationMessage: [],
			guidanceIsPopup: false,
			isUpdated: false,
			showGuidance: false,
			dictionaryKey: "ConfidentialityKey",
			type: FieldType.Select,
			selectType: SelectFieldType.SingleSelect,
			defaultValue: HumanResourcesIsDefaulted,
			value: HumanResourcesIsDefaulted,
			data: new Map<number, string>([
				[1, t("display:labelHumanResources")],
				[2, t("display:labelAlternativeHumanResources")],
			]),
			attachmentsEnabled: false,
			actionsEnabled: false,
			isScannable: false,
		});
	}

	private getApplicableFieldsAnswers(fields: Field[], sectionId: number) {
		return fields
			.filter((f) => f.groupId && f.id >= 0 && f.sectionId === sectionId)
			.reduce<Field[]>((acc, field) => {
				if (field.relatedFields && field.relatedFields.length > 0) {
					if (
						!((field as IndirectField).originalField as OptionalSelectField)
							.selectedFromRegister
					) {
						acc.push(...field.relatedFields);
					} else {
						const fields = field.relatedFields.map((f) => {
							if (f.id === field.id) {
								return {
									...f,
									value: field.value.id,
								};
							}

							return f;
						});
						acc.push(...fields);
					}
				} else {
					acc.push(field);
				}

				return acc;
			}, [])
			.map((f) => mapPortalFieldToAnswerDto(f));
	}
}
