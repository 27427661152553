import { useState, useEffect, useMemo } from "react";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { AreFieldArraysEqual } from "../../../../../../helpers/EqualityHelper";
import { clearOrphanedAttachmentsFromState } from "../../../../../../state/components/attachment";
import { ConfirmationModal } from "../../../../../components/modals";
import { SubModuleFieldsValidator } from "../../../../../../validators/subModuleFieldsValidator";
import { updateField } from "../../../../../../state/components/questionnaire";
import { useAppDispatch } from "../../../../../../state/hooks/useAppDispatch";
import { SubModuleDialogPresentation } from "./SubModuleDialog.presentation";
import type { Field } from "../../../../../../models/fields/Field";
import type { FieldType, SubModule, SubModuleRecord } from "../../../../../../models/questionnaire";
import type { Portal } from "../../../../../../models/portal";
import type { State } from "../../../../../../state";

interface Props {
	cancel: () => void;
	isInSubmodule?: boolean;
	isOpen: boolean;
	parentId: number | string;
	saveRecord: (subModuleRecord: SubModuleRecord) => void;
	subModuleId: number;
	subModuleRecordId?: string;
}

export const SubModuleDialogContainer = ({
	cancel,
	isInSubmodule,
	isOpen,
	parentId,
	saveRecord,
	subModuleId,
	subModuleRecordId,
}: Props) => {
	const { t } = useTranslation();
	const { userData } = useAuth();

	const dispatch = useAppDispatch();
	const templateId = useSelector<State, string>((state) => state.questionnaire.questionnaire!.id);

	const subModule = useSelector<State, SubModule | undefined>((state) =>
		state.questionnaire.subModules.find((x) => x.id === subModuleId),
	);

	const toEdit = useSelector<State, SubModuleRecord | undefined>((state) =>
		state.questionnaire.subModuleRecords.find((x) => x.localId === subModuleRecordId),
	);

	const [fields, setFields] = useState(
		useSelector<State, Field[]>(
			(state) =>
				state.questionnaire.fields
					.filter((x) => x.subModuleId === subModuleId)
					.sort((a, b) => a.orderIndex - b.orderIndex),
			AreFieldArraysEqual,
		),
	);

	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find((p) =>
			state.questionnaire.questionnaire
				? p.key === state.questionnaire.questionnaire.portalKey
				: undefined,
		),
	);

	const [record, setRecord] = useState<SubModuleRecord | undefined>();
	const [updated, setUpdated] = useState(false);
	const [displayErrors, setDisplayErrors] = useState(false);
	const [errorMessages, setErrorMessages] = useState<string[] | undefined>(undefined);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
	const [isFormDirty, setIsFormDirty] = useState(false);

	const portalCulture = portal ? portal.dateFormat : undefined;

	let errorsDiv: HTMLDivElement;
	const subModuleFieldValidator = useMemo(
		() => new SubModuleFieldsValidator(portalCulture),
		[portalCulture],
	);

	const localId = useMemo(() => {
		if (toEdit && toEdit.localId) {
			return toEdit.localId;
		}
		if (record && record.localId) {
			return record.localId;
		}

		return uuid();
	}, [record, toEdit]);

	useEffect(() => {
		if (!subModuleRecordId) {
			fields.forEach((f) => {
				if (f.defaultValue != null) {
					storeFieldValue(f.id, f.defaultValue, f.type);
				}
			});
		}

		if (subModuleRecordId && toEdit) {
			const dialogFields = fields;

			dialogFields.forEach((t) => {
				const editRecordValue = toEdit.values.find((x) => x.id === t.id);

				if (editRecordValue) {
					t.value = editRecordValue.value;
					storeFieldValue(editRecordValue.id, editRecordValue.value, t.type);
				}
			});

			setFields(dialogFields);
		}
	}, [subModuleRecordId, record]);

	if (!subModule) {
		return null;
	}

	const setErrorsDiv = (div: HTMLDivElement) => {
		errorsDiv = div;
	};

	const storeFieldValue = (
		fieldId: number,
		value: any,
		type: FieldType,
		isFieldChange?: boolean,
	) => {
		let currentRecord = record;

		if (currentRecord === undefined) {
			currentRecord = {
				localId,
				subModuleId: subModule.id,
				values: [{ id: fieldId, type, value: value as string }],
				parentId,
			};
		} else {
			const index = currentRecord.values.findIndex((f) => f.id === fieldId);

			if (index === -1) {
				currentRecord.values.push({ id: fieldId, type, value });
			} else {
				currentRecord.values[index].value = value;
			}
		}

		dispatch(updateField(fieldId, value, true));

		const dialogFields = fields.map((field) => ({
			...field,
			value: field.id === fieldId ? value : field.value,
		}));

		setFields(dialogFields);
		populateErrors();
		setRecord(currentRecord);

		if (isFieldChange && !isFormDirty) {
			setIsFormDirty(true);
		}
	};

	const updateValidationStatus = (fieldId: number, value: string[]) => {
		const dialogFields = fields.map((field) => ({
			...field,
			validationMessage: field.id === fieldId ? value : field.validationMessage,
		}));

		setFields(dialogFields);
	};

	const saveButtonClicked = () => {
		if (record) {
			if (isSubModuleRecordValid()) {
				const recordToSave = record;

				setIsFormDirty(false);
				setDisplayErrors(false);
				setRecord(undefined);
				clearFields();

				saveRecord(recordToSave);
			} else {
				displayErrorHeader();
			}
		} else if (!isSubModuleRecordValid()) {
			displayErrorHeader();
		}
	};

	const displayErrorHeader = () => {
		populateErrors();
		setDisplayErrors(true);

		errorsDiv.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	const populateErrors = () => {
		const messages: string[] = fields
			.filter((field) => field.validationMessage.length > 0 && !field.hidden)
			.map((field) => `${field.name}: ${field.validationMessage[0]}`);

		setErrorMessages(messages);
	};

	const doCancel = () => {
		setIsFormDirty(false);
		setRecord(undefined);
		setDisplayErrors(false);
		clearFields();
		dispatch(clearOrphanedAttachmentsFromState());
		cancel();
	};

	const cancelButtonClicked = () => {
		if (isFormDirty) {
			setIsConfirmationModalOpen(true);
		} else {
			doCancel();
		}
	};

	const clearFields = () => {
		const toClear = fields;

		toClear.forEach((f) => {
			f.value = f.defaultValue || undefined;
			f.validationMessage = [];
			dispatch(updateField(f.id, f.value, true));
		});

		setFields(toClear);
		setErrorMessages(undefined);
	};

	const isSubModuleRecordValid = (): boolean => {
		let isValid = true;
		const fieldsToValidate = fields;

		if (fieldsToValidate && record) {
			fieldsToValidate.forEach((field) => {
				const rValue = record.values.find((record) => record.id === field.id);
				const recordValue = rValue && rValue.value !== undefined ? rValue.value : undefined;
				const valid = validateField(field, recordValue);

				if (valid.length > 0 && !field.hidden) {
					isValid = false;
				}

				field.validationMessage = valid;
			});

			setUpdated(!updated);
		} else if (fieldsToValidate && !record) {
			fieldsToValidate.forEach((f) => {
				const valid = validateField(f, undefined);

				if (valid.length > 0 && !f.hidden) {
					isValid = false;
				}

				f.validationMessage = valid;
				setUpdated(!updated);
			});
		}

		setFields(fieldsToValidate);

		return isValid;
	};

	const validateField = (field: Field, value: any): string[] => {
		return subModuleFieldValidator.isFieldValid(field, value);
	};

	return (
		<>
			<SubModuleDialogPresentation
				allowActions={subModule.allowActions}
				allowAttachments={subModule.allowAttachments}
				cancel={cancelButtonClicked}
				displayValidation={displayErrors}
				fields={fields || []}
				isAuthenticated={!!userData}
				isEdit={toEdit !== null && toEdit !== undefined}
				isOpen={isOpen}
				parentId={localId}
				questionnaireId={templateId}
				saveRecord={saveButtonClicked}
				setErrorsDiv={setErrorsDiv}
				storeFieldValue={(id, value, type) => storeFieldValue(id, value, type, true)}
				subModuleErrors={errorMessages}
				subModuleId={subModuleId}
				title={isInSubmodule ? t("display:labelAction") : subModule.name}
				updateFieldValidation={updateValidationStatus}
			/>

			<ConfirmationModal
				onCancel={() => setIsConfirmationModalOpen(false)}
				onConfirm={doCancel}
				show={isConfirmationModalOpen}
				text={t("display:labelAreYouSure")}
			/>
		</>
	);
};
