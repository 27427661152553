import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../TextInput";
import { FormModal } from "../../modals";
import { Checkbox } from "../../Checkbox";
import { useFullDictionary } from "../../../utilities/UseFullDictionary";
import { SearchBox } from "../SearchBox";
import type { SelectableTextField } from "../../../../models/fields/SelectableTextField";
import "./TextInputSelectable.styles.scss";

interface Props {
	field: SelectableTextField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
	onSelectorChange: (value: number) => void;
	isModalOpen: boolean;
	onModalOpen: () => void;
	onModalOk: () => void;
	onModalCancel: () => void;
	isTextValidationRequired: boolean;
	onTextValidation: () => void;
	selectedValues: number[];
}

export const TextInputSelectablePresentation = ({
	field,
	isModalOpen,
	isTextValidationRequired,
	onModalCancel,
	onModalOk,
	onModalOpen,
	onSelectorChange,
	onTextValidation,
	selectedValues,
	updateField,
	updateValidationStatus,
}: Props) => {
	const { t } = useTranslation();
	const searchBoxRef = useRef(null);
	const [searchTerm, setSearchTerm] = useState("");
	const fullDictionary = useFullDictionary(field, searchTerm);

	const onSearchTermChange = (searchTerm: string) => {
		setSearchTerm(searchTerm);
	};

	const handleModalOpen = () => {
		onModalOpen();
		setSearchTerm("");
	};

	const filteredData = useMemo(() => {
		const searchTermLowerCase = searchTerm.trim().toLocaleLowerCase();
		if (searchTermLowerCase && field.data) {
			return Array.from(field.data).filter((item) => {
				return item[1] && item[1].toLocaleLowerCase().includes(searchTermLowerCase);
			});
		}
		return (field.data && Array.from(field.data)) || [];
	}, [searchTerm, field.data]);

	const emptyListMessage = useMemo(() => {
		if (
			filteredData.length === 0 &&
			(!fullDictionary ||
				(fullDictionary && fullDictionary.every((category) => category.length === 0)))
		) {
			return searchTerm.trim()
				? t("display:labelNoPickListSearchResults")
				: t("display:labelNoPickListValues");
		}
	}, [filteredData, fullDictionary, searchTerm, t]);

	return (
		<>
			<TextInput
				isExternalValidationRequired={isTextValidationRequired}
				onValidation={onTextValidation}
				textField={field}
				updateField={updateField}
				updateValidationStatus={updateValidationStatus}
			/>
			<div>
				<button
					className="she-btn she-btn-tertiary"
					onClick={handleModalOpen}
					type="button"
				>
					{t("display:labelSelectFromList")}
				</button>
			</div>
			<FormModal
				large
				onCancel={onModalCancel}
				onOk={onModalOk}
				show={isModalOpen}
				title={field.name}
			>
				<div className="she-text-input-selectable__search-box">
					<SearchBox
						onSearchBegan={() => {}}
						onSearchEnd={() => {}}
						onSearchTermChange={onSearchTermChange}
						reference={searchBoxRef}
						searchTerm={searchTerm}
					/>
				</div>
				{emptyListMessage && <p>{emptyListMessage}</p>}
				{filteredData.length > 0 && (
					<div role="group">
						<ul className="she-components-checkbox-group">
							{filteredData.map((item) => (
								<li className="she-components-checkbox" key={item[0]}>
									<Checkbox
										checked={selectedValues.includes(item[0])}
										id={`${field.id}_${item[0]}${item[1]
											.toString()
											.replace(/[\W]+/g, "_")}`}
										name={item[1]}
										onChange={() => {
											onSelectorChange(item[0]);
										}}
										value={item[0]}
									/>
								</li>
							))}
						</ul>
					</div>
				)}
				{fullDictionary && (
					<div role="group">
						{fullDictionary.map((collection) => {
							if (collection.length) {
								return (
									<div className="she-components-control" key={collection[0][2]}>
										{collection[0][2] !== "" && (
											<div className="she-components-control-question">
												{collection[0][2]}
											</div>
										)}
										<ul className="she-components-checkbox-group">
											{collection.map((item) => (
												<li
													className="she-components-checkbox"
													key={item[0]}
												>
													<Checkbox
														checked={selectedValues.includes(item[0])}
														id={`${field.id}_${item[0]}${item[1]}${item[2]}`.replace(
															/[\W]+/g,
															"_",
														)}
														name={item[1]}
														onChange={() => {
															onSelectorChange(item[0]);
														}}
														value={item[0]}
													/>
												</li>
											))}
										</ul>
									</div>
								);
							}
							return null;
						})}
					</div>
				)}
			</FormModal>
		</>
	);
};
