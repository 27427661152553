import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { fileSizeError } from "../../../../../state/components/questionnaire";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { ReactComponent as FilesTooBig } from "../../../../assets/svg/illustrations/fileTooBig.svg";
import { useAppDispatch } from "../../../../../state/hooks/useAppDispatch";
import type { State } from "../../../../../state";

export const FileSizeErrorModal = () => {
	const { t } = useTranslation();
	const show = useSelector<State, boolean | undefined>(
		(state) => state.questionnaire.status.fileSizeError,
	);
	const dispatch = useAppDispatch();

	const onCancel = () => {
		dispatch(fileSizeError(false));
	};

	return (
		<IllustratedThemedModal
			cancelText={t("global:ok")}
			onCancel={onCancel}
			show={!!show}
			text={t("validation:attachmentSizeIncorrect")}
		>
			<FilesTooBig />
		</IllustratedThemedModal>
	);
};
