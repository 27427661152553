import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BasicEntitySelectInputList } from "../BasicEntitySelectInputList";
import { FormModal, ConfirmationModal } from "../../modals";
import { BasicEntitySelectInputPresentation } from "./BasicEntitySelectInput.presentation";
import type { Entity } from "../../../../models/entity";
import type { Portal } from "../../../../models/portal";
import type { State } from "../../../../state";

interface Props {
	caption: string;
	onChange: (value: Entity | undefined | null) => void;
	propertyName: string;
	value?: Entity | null;
	initialSearch?: string;
	onBlur?: () => void;
}

export const BasicEntitySelectInput = ({
	caption,
	onChange,
	propertyName,
	value,
	initialSearch,
	onBlur,
}: Props) => {
	const { t } = useTranslation();
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [checkedEntity, setCheckedEntity] = useState<Entity | undefined | null>(value);
	const [tempEntity, setTempEntity] = useState<Entity | undefined | null>(value);

	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find(
			(p) =>
				state.questionnaire.questionnaire &&
				p.key === state.questionnaire.questionnaire.portalKey,
		),
	);

	const onDisplayModal = (event: React.MouseEvent) => {
		event.preventDefault();

		setShowSelectModal(true);
		setTempEntity(checkedEntity);
	};

	const onCancel = () => {
		setShowSelectModal(false);
	};

	const onOk = () => {
		setCheckedEntity(tempEntity);
		setShowSelectModal(false);
		onChange(tempEntity);
		onBlur?.();
	};

	const onRemove = () => {
		setCheckedEntity(undefined);
		setShowRemoveModal(false);
		onChange(null);
		onBlur?.();
	};

	const handleRemove = (event: React.MouseEvent) => {
		event.preventDefault();

		setShowRemoveModal(true);
	};

	const buttonLabel = useMemo(
		() =>
			t(checkedEntity ? "display:labelChangeEntity" : "display:labelSelectEntity", {
				entityCaption: caption,
			}),
		[t, caption, checkedEntity],
	);

	return (
		<>
			<BasicEntitySelectInputPresentation
				buttonLabel={buttonLabel}
				entity={value}
				onClick={onDisplayModal}
				onRemove={handleRemove}
				removeLabel={t("display:buttonDelete")}
			/>
			<FormModal
				large
				onCancel={onCancel}
				onOk={onOk}
				padded={false}
				show={showSelectModal}
				title={t("display:labelSelectEntity", {
					entityCaption: caption,
				})}
			>
				<BasicEntitySelectInputList
					initialSearch={initialSearch}
					onSelectEntity={setTempEntity}
					portalCulture={portal ? portal.dateFormat : "Uk"}
					propertyName={propertyName}
					selectedEntity={tempEntity}
				/>
			</FormModal>
			<ConfirmationModal
				onCancel={() => {
					setShowRemoveModal(false);
				}}
				onConfirm={onRemove}
				show={showRemoveModal}
				text={t("display:labelClearEntitySelectField")}
			/>
		</>
	);
};
