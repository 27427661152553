import type { UseFormRegisterReturn } from "react-hook-form";

interface Props {
	input: {
		label: string;
	} & UseFormRegisterReturn;
	errors?: {
		types?: object;
	};
}

export const PasswordInput = ({ input, errors }: Props) => {
	return (
		<div
			className={`she-components-control 
      ${errors ? "she-components-validation-border-left" : ""}
            `}
		>
			<label className="she-components-control-question" htmlFor={input.name}>
				{input.label}
			</label>

			{errors?.types &&
				Object.entries(errors.types).map(([type, message]) => (
					<div className="she-components-validation-message" key={type}>
						{message}
					</div>
				))}
			<input
				className="she-components-text-input"
				id={input.name}
				type="password"
				{...input}
			/>
		</div>
	);
};
