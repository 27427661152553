import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { switchMatchingLanguageCode } from "../../../../helpers/LanguageCodeHelper";
import { DefaultActionsService } from "../../../../services/actions";
import { CancelablePromise } from "../../../../services/utilities/CancelablePromise";

export const useGetActionMetadataQuery = () => {
	const service = new DefaultActionsService({ subdomain: "Action" });
	const { i18n } = useTranslation();
	const language = switchMatchingLanguageCode(i18n.language);

	return useQuery({
		queryKey: ["ActionMetadata", language],

		queryFn: () => {
			const cancelTokenSource = axios.CancelToken.source();
			const getMetadata = new CancelablePromise<any>((resolve, reject) =>
				service
					.getMetadata(language || "EnGB")
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);
			getMetadata.cancel = () => {
				cancelTokenSource.cancel();
			};
			return getMetadata;
		},
	});
};
