import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ActionField } from "../ActionField";
import { loadOrgUnits } from "../../../../../state";
import { Dropdown } from "../../../../components/input/DropDown";
import { EmptyPicklistMessage } from "../EmptyPicklistMessage/EmptyPicklistMessage";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { ReactComponent as Calendar } from "../../../../assets/svg/calendar.svg";
import { BasicFileInput } from "../../../../components/input/BasicFileInput/BasicFileInput.component";
import { ActionAttachmentsList } from "../ActionAttachmentsList";
import { BasicDateInput } from "../../../../components/input/BasicDateInput";
import { getCustomerKeyLowercase } from "../../../../../helpers/AuthenticationHelpers";
import { usePreventBrowserReload } from "../../../../utilities/UsePreventBrowserReload";
import { useFilteredOptions } from "../../hooks/useFilteredOptions";
import { useResetFilteredOptions } from "../../hooks/useResetFilteredOptions";
import { BasicOrgUnitSelector } from "../../../../components/input/BasicOrgUnitSelector";
import { useGetActionMetadataQuery } from "../../hooks/useGetActionMetadataQuery";
import { useGetActionDictionaryQuery } from "../../hooks/useGetActionDictionaryQuery";
import { useActionLabelAndGuidance } from "../../hooks/useActionLabelAndGuidance";
import { ActionReadOnlyField } from "../ActionReadOnlyField/ActionReadOnlyField.component";
import { BasicEntitySelectInput } from "../../../../components/input/BasicEntitySelectInput";
import { ErrorViewer } from "../../../../components/ErrorViewer/ErrorViewer.component";
import { ActionCommentMandatory, type ActionAttachment } from "../../../../../models/action";
import { useAppDispatch } from "../../../../../state/hooks/useAppDispatch";
import type { OrgUnit } from "../../../../../models/orgUnit";
import type { State } from "../../../../../state";
import type { Entity } from "../../../../../models/entity";
import "./CreateAction.styles.scss";

interface Props {
	addAttachment: (file: File, identifier?: string, description?: string) => void;
	attachmentsToDisplay: ActionAttachment[];
	removeAttachment: (identifier: string) => void;
	resetAttachments: () => void;
}

export const CreateAction = ({
	addAttachment,
	attachmentsToDisplay,
	removeAttachment,
	resetAttachments,
}: Props) => {
	const { control, formState, register, reset, watch, setValue } = useFormContext();

	useEffect(() => {
		reset();
		resetAttachments();
		// Make sure it runs only once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const customerKey = getCustomerKeyLowercase();
	const orgUnits = useSelector<State, OrgUnit[]>((s) => s.orgUnit.orgUnits);

	const formattedDateCreated = useMemo(() => moment(new Date()).format(getDateFormat()), []);

	useEffect(() => {
		dispatch(loadOrgUnits(0, customerKey));
	}, [customerKey, dispatch]);

	const metadata = useGetActionMetadataQuery();

	const { actionDictionariesQuery, categoryOptions, priorityOptions, subCategoryOptions } =
		useGetActionDictionaryQuery();

	const selectedOrgUnit = watch("OrgUnitId");

	const selectedOrgUnitName = useMemo(() => {
		if (selectedOrgUnit) {
			const orgUnit = orgUnits.find((orgUnit) => orgUnit.id === selectedOrgUnit);
			return orgUnit && orgUnit.name;
		}
	}, [selectedOrgUnit, orgUnits]);

	const selectedCategory = parseInt(watch("ActionCategory"));
	const filteredCategoryOptions = useFilteredOptions(categoryOptions ?? [], selectedOrgUnit);
	useResetFilteredOptions(
		filteredCategoryOptions,
		selectedCategory,
		"ActionCategory",
		setValue,
		actionDictionariesQuery.isSuccess,
	);

	const selectedSubCategory = parseInt(watch("ActionSubCategory"));
	const filteredSubCategoryOptions = useFilteredOptions(
		subCategoryOptions ?? [],
		selectedOrgUnit,
		selectedCategory,
	);
	useResetFilteredOptions(
		filteredSubCategoryOptions,
		selectedSubCategory,
		"ActionSubCategory",
		setValue,
		actionDictionariesQuery.isSuccess,
	);

	const watchCompletePercent = watch("CompletePercent");
	if (
		watchCompletePercent &&
		parseInt(watchCompletePercent) !== parseFloat(watchCompletePercent)
	) {
		setValue("CompletePercent", Math.round(parseInt(watchCompletePercent)));
	}

	usePreventBrowserReload(formState.isDirty);

	const handleOnAddAttachments = (files: File[]) => {
		files.forEach((file) => addAttachment(file));
	};

	const orgUnitNameFieldInfo = useActionLabelAndGuidance("OrgUnitName", metadata.data);
	const actionHeaderFieldInfo = useActionLabelAndGuidance("ActionHeader", metadata.data);
	const actionCategoryFieldInfo = useActionLabelAndGuidance("ActionCategory", metadata.data);
	const actionSubCategoryFieldInfo = useActionLabelAndGuidance(
		"ActionSubCategory",
		metadata.data,
	);
	const actionDetailFieldInfo = useActionLabelAndGuidance("ActionDetail", metadata.data);
	const forUserFieldInfo = useActionLabelAndGuidance("ForUser", metadata.data);
	const dueDateFieldInfo = useActionLabelAndGuidance("DueDate", metadata.data);
	const priorityFieldInfo = useActionLabelAndGuidance("Priority", metadata.data);
	const completePercentFieldInfo = useActionLabelAndGuidance("CompletePercent", metadata.data);
	const noteCommentFieldInfo = useActionLabelAndGuidance("NoteComment", metadata.data);
	const [isNoteCommentRequired, setIsNoteCommentRequired] = useState(false);

	const isCommentMandatory = metadata.data?.find(
		({ PropertyName }: { PropertyName: string }) => PropertyName === "NoteComment",
	)?.IsCommentMandatory;

	if (actionDictionariesQuery.isLoading || metadata.isLoading) {
		return <div>{t("display:labelLoading")}</div>;
	}

	if (actionDictionariesQuery.isError || metadata.isError) {
		return (
			<>
				<div>{t("display:labelError")}</div>
				<ErrorViewer error={actionDictionariesQuery.error || metadata.error} />
			</>
		);
	}

	const getErrors = (field: string) => {
		return (
			formState.errors[field] &&
			Object.values(formState.errors[field]?.types ?? {})
				.map((error) => (typeof error === "string" ? error : ""))
				.filter(Boolean)
		);
	};

	return (
		<div className="she-action-detail">
			<ActionField
				fieldId="OrgUnitId"
				guidance={orgUnitNameFieldInfo.guidance}
				guidanceIsPopup={orgUnitNameFieldInfo.guidanceIsPopup}
				required={orgUnitNameFieldInfo.isMandatory}
				showGuidance={orgUnitNameFieldInfo.showGuidance}
				title={orgUnitNameFieldInfo.label}
				validationErrors={getErrors("OrgUnitId")}
			>
				<Controller
					control={control}
					defaultValue=""
					name="OrgUnitId"
					render={({ field: { ...props } }) => {
						return (
							<BasicOrgUnitSelector
								caption={orgUnitNameFieldInfo.label}
								fieldGuid="OrgUnitId"
								isValid
								orgUnits={orgUnits}
								{...{ ...props, ref: null }}
							/>
						);
					}}
					rules={{
						required:
							orgUnitNameFieldInfo.isMandatory &&
							`${t("validation:mandatoryFieldMessage")}`,
						validate: (value: number) => {
							if (orgUnitNameFieldInfo.isMandatory && !value) {
								return `${t("validation:mandatoryFieldMessage")}`;
							}
							return true;
						},
					}}
				/>
			</ActionField>

			<ActionField
				fieldId="ActionHeader"
				guidance={actionHeaderFieldInfo.guidance}
				guidanceIsPopup={actionHeaderFieldInfo.guidanceIsPopup}
				required={actionHeaderFieldInfo.isMandatory}
				showGuidance={actionHeaderFieldInfo.showGuidance}
				title={actionHeaderFieldInfo.label}
				validationErrors={getErrors("ActionHeader")}
			>
				<input
					className="she-components-text-input"
					defaultValue={actionHeaderFieldInfo.defaultValue || ""}
					{...register("ActionHeader", {
						maxLength: 255,
						validate: (value: string) => {
							if (!value) {
								return (
									actionHeaderFieldInfo.isMandatory &&
									`${t("validation:mandatoryFieldMessage")}`
								);
							}
						},
					})}
				/>
			</ActionField>

			<ActionField
				fieldId="ActionCategory"
				guidance={actionCategoryFieldInfo.guidance}
				guidanceIsPopup={actionCategoryFieldInfo.guidanceIsPopup}
				required={actionCategoryFieldInfo.isMandatory}
				showGuidance={actionCategoryFieldInfo.showGuidance}
				title={actionCategoryFieldInfo.label}
				validationErrors={getErrors("ActionCategory")}
			>
				<Controller
					control={control}
					defaultValue={
						actionCategoryFieldInfo.defaultValue
							? parseInt(actionCategoryFieldInfo.defaultValue) || ""
							: ""
					}
					name="ActionCategory"
					render={({ field: { ...props } }) => (
						<EmptyPicklistMessage
							isEmpty={!filteredCategoryOptions.length}
							isMandatory={actionCategoryFieldInfo.isMandatory}
						>
							<Dropdown
								data={filteredCategoryOptions}
								guid="category"
								isMandatory={actionCategoryFieldInfo.isMandatory}
								isTopOptionTitle
								{...{ ...props, ref: null }}
							/>
						</EmptyPicklistMessage>
					)}
					rules={{
						validate: (value: string) => {
							if (actionCategoryFieldInfo.isMandatory && (!value || value === "-1")) {
								return `${t("validation:mandatoryFieldMessage")}`;
							}
							return true;
						},
					}}
				/>
			</ActionField>
			{actionSubCategoryFieldInfo.visible ? (
				<ActionField
					fieldId="ActionSubCategory"
					guidance={actionSubCategoryFieldInfo.guidance}
					guidanceIsPopup={actionSubCategoryFieldInfo.guidanceIsPopup}
					required={actionSubCategoryFieldInfo.isMandatory}
					showGuidance={actionSubCategoryFieldInfo.showGuidance}
					title={actionSubCategoryFieldInfo.label}
					validationErrors={getErrors("ActionSubCategory")}
				>
					<Controller
						control={control}
						defaultValue={
							actionSubCategoryFieldInfo.defaultValue
								? parseInt(actionSubCategoryFieldInfo.defaultValue) || ""
								: ""
						}
						name="ActionSubCategory"
						render={({ field: { ...props } }) => (
							<EmptyPicklistMessage
								isEmpty={!filteredSubCategoryOptions.length}
								isMandatory={actionSubCategoryFieldInfo.isMandatory}
							>
								<Dropdown
									data={filteredSubCategoryOptions}
									guid="subCategory"
									isMandatory={actionSubCategoryFieldInfo.isMandatory}
									isTopOptionTitle
									{...{ ...props, ref: null }}
								/>
							</EmptyPicklistMessage>
						)}
						rules={{
							validate: (value: string) => {
								if (
									actionSubCategoryFieldInfo.isMandatory &&
									(!value || value === "-1")
								) {
									return `${t("validation:mandatoryFieldMessage")}`;
								}
								return true;
							},
						}}
					/>
				</ActionField>
			) : null}

			<ActionField
				fieldId="ActionDetail"
				guidance={actionDetailFieldInfo.guidance}
				guidanceIsPopup={actionDetailFieldInfo.guidanceIsPopup}
				required={actionDetailFieldInfo.isMandatory}
				showGuidance={actionDetailFieldInfo.showGuidance}
				title={actionDetailFieldInfo.label}
				validationErrors={getErrors("ActionDetail")}
			>
				<Controller
					control={control}
					defaultValue={actionDetailFieldInfo.defaultValue || ""}
					name="ActionDetail"
					render={({ field: { ...props } }) => (
						<TextareaAutosize
							className="she-components-text-input"
							maxLength={10000}
							required={actionDetailFieldInfo.isMandatory}
							{...props}
						/>
					)}
					rules={{
						maxLength: 10000,
						required:
							actionDetailFieldInfo.isMandatory &&
							`${t("validation:mandatoryFieldMessage")}`,
					}}
				/>
			</ActionField>

			<ActionField
				fieldId="ForUserIds"
				guidance={forUserFieldInfo.guidance}
				guidanceIsPopup={forUserFieldInfo.guidanceIsPopup}
				required
				showGuidance={forUserFieldInfo.showGuidance}
				title={forUserFieldInfo.label}
				validationErrors={getErrors("ForUserIds")}
			>
				<Controller
					control={control}
					defaultValue=""
					name="ForUserIds"
					render={({ field: { ...props } }) => (
						<BasicEntitySelectInput
							caption={forUserFieldInfo.label}
							initialSearch={selectedOrgUnitName && `"${selectedOrgUnitName}"`}
							propertyName="ForUser"
							{...{ ...props, ref: null }}
						/>
					)}
					rules={{
						validate: (value?: Entity) => {
							if (!value) {
								return `${t("validation:mandatoryFieldMessage")}`;
							}
							return true;
						},
					}}
				/>
			</ActionField>

			<ActionReadOnlyField info={metadata.data} propertyName="DateCreated">
				<div className="she-icon she-action-detail__icon">
					<Calendar />
				</div>
				{formattedDateCreated}
			</ActionReadOnlyField>

			<ActionField
				fieldId="DueDate"
				guidance={dueDateFieldInfo.guidance}
				guidanceIsPopup={dueDateFieldInfo.guidanceIsPopup}
				required={dueDateFieldInfo.isMandatory}
				showGuidance={dueDateFieldInfo.showGuidance}
				title={dueDateFieldInfo.label}
				validationErrors={getErrors("DueDate")}
			>
				<Controller
					control={control}
					defaultValue={dueDateFieldInfo.defaultValue || ""}
					name="DueDate"
					render={({ field: { ...props } }) => (
						<BasicDateInput
							fieldId="DueDate"
							{...props}
							dateFormat={getDateFormat()}
							ref={null}
						/>
					)}
					rules={{
						validate: (value: string) => {
							// TODO: Look into this, they are both using the same logic, so the second branch is never being hit
							if (value === "//") {
								return true;
								// eslint-disable-next-line no-dupe-else-if
							} else if (value === "//") {
								return `${t("validation:mandatoryFieldMessage")}`;
							}

							const selectedDate = moment(value, getDateFormat(), true);
							const today = moment();
							const dateIsValid =
								selectedDate.isValid() && selectedDate.isSameOrAfter(today, "day");

							if (
								dateIsValid &&
								!selectedDate.isSameOrBefore("2200-12-31T00:00:00")
							) {
								return `${t("validation:invalidDateRange")}`;
							}

							return dateIsValid || `${t("validation:invalidDateMessage")}`;
						},
					}}
				/>
			</ActionField>

			<ActionField
				fieldId="Priority"
				guidance={priorityFieldInfo.guidance}
				guidanceIsPopup={priorityFieldInfo.guidanceIsPopup}
				required={priorityFieldInfo.isMandatory}
				showGuidance={priorityFieldInfo.showGuidance}
				title={priorityFieldInfo.label}
				validationErrors={getErrors("Priority")}
			>
				<Controller
					control={control}
					defaultValue={priorityFieldInfo.defaultValue || 1}
					name="Priority"
					render={({ field: { ...props } }) => (
						<Dropdown
							data={priorityOptions ?? []}
							guid="priority"
							isMandatory
							isTopOptionTitle={false}
							{...{ ...props, ref: null }}
						/>
					)}
					rules={{
						required:
							priorityFieldInfo.isMandatory &&
							`${t("validation:mandatoryFieldMessage")}`,
					}}
				/>
			</ActionField>
			<ActionField
				fieldId="CompletePercent"
				guidance={completePercentFieldInfo.guidance}
				guidanceIsPopup={completePercentFieldInfo.guidanceIsPopup}
				required={completePercentFieldInfo.isMandatory}
				showGuidance={completePercentFieldInfo.showGuidance}
				title={completePercentFieldInfo.label}
				validationErrors={getErrors("CompletePercent")}
			>
				<input
					className="she-components-text-input"
					defaultValue={completePercentFieldInfo.defaultValue || "0"}
					max="100"
					min="0"
					{...register("CompletePercent", {
						required: `${t("validation:mandatoryFieldMessage")}`,
						validate: (value: any) => {
							setIsNoteCommentRequired(
								value > 99 &&
									isCommentMandatory === ActionCommentMandatory.OnCompletion,
							);
							if (value < 0 || value > 100) {
								return `${t("validation:percentageMessage")}`;
							}
						},
					})}
					type="number"
				/>
			</ActionField>

			<ActionField
				fieldId="NoteComment"
				guidance={noteCommentFieldInfo.guidance}
				guidanceIsPopup={noteCommentFieldInfo.guidanceIsPopup}
				required={
					isNoteCommentRequired || isCommentMandatory === ActionCommentMandatory.Always
				}
				showGuidance={noteCommentFieldInfo.showGuidance}
				title={noteCommentFieldInfo.label}
				validationErrors={getErrors("NoteComment")}
			>
				<Controller
					control={control}
					defaultValue={noteCommentFieldInfo.defaultValue || ""}
					name="NoteComment"
					render={({ field: { ...props } }) => (
						<TextareaAutosize
							className="she-components-text-input"
							maxLength={100000}
							{...props}
						/>
					)}
					rules={{
						maxLength: 100000,
						required:
							isNoteCommentRequired ||
							isCommentMandatory === ActionCommentMandatory.Always
								? t("validation:mandatoryFieldMessage")
								: undefined,
						validate: (value: string) => {
							if (
								isNoteCommentRequired ||
								isCommentMandatory === ActionCommentMandatory.Always
							) {
								if (!value || value.trim().length === 0) {
									return `${t("validation:mandatoryFieldMessage")}`;
								}
							}
						},
					}}
				/>
			</ActionField>

			<ActionAttachmentsList
				attachments={attachmentsToDisplay}
				readonly={false}
				removeAttachment={removeAttachment}
			/>

			<BasicFileInput name="action-" onAddAttachments={handleOnAddAttachments} />
		</div>
	);
};
