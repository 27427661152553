import { useState, useEffect, forwardRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	createSubModuleRecord,
	removeSubModuleRecord,
	editSubModuleRecord,
	updateFieldProperty,
} from "../../../../../../state/components/questionnaire";
import { SubModuleDialog } from "../SubModuleDialog";
import { SubmoduleType } from "../../../../../../models/questionnaire";
import { ConfirmationModal } from "../../../../../components/modals";
import { UpdatableFieldProperty } from "../../../../../../state/components/questionnaire/actions/enums";
import { PPESubmodule } from "../PPESubmodule/PPESubmodule.component";
import { useAppDispatch } from "../../../../../../state/hooks/useAppDispatch";
import { SubmodulePresentation } from "./Submodule.presentation";
import type { State } from "../../../../../../state";
import type { SubModule, SubModuleRecord } from "../../../../../../models/questionnaire";

interface Props {
	subModuleId: number;
	parentId: number | string;
	isIQ?: boolean;
	parentGuid?: string;
	isMandatory: boolean;
	hasValidationErrors: boolean;
	validationErrors: string[];
	isInSubmodule?: boolean;
}

export const SubModuleContainer = forwardRef<HTMLHeadingElement, Props>(
	(
		{
			hasValidationErrors,
			isMandatory,
			parentId,
			subModuleId,
			validationErrors,
			isIQ,
			isInSubmodule,
			parentGuid,
		}: Props,
		ref,
	) => {
		const { t } = useTranslation();
		const dispatch = useAppDispatch();
		const subModule = useSelector<State, SubModule | undefined>((state) =>
			state.questionnaire.subModules.find((x) => x.id === subModuleId),
		);
		const subModuleRecords = useSelector<State, SubModuleRecord[] | undefined>(
			(state) =>
				state.questionnaire.subModuleRecords.filter(
					(x) => x.subModuleId === subModuleId && x.parentId === parentId,
				),
			shallowEqual,
		);

		const [isOpen, setIsOpen] = useState(false);
		const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
		const [subModRecordToEdit, setSubModRecordToEdit] = useState<SubModuleRecord | undefined>(
			undefined,
		);
		const [deleteMessage, setDeleteMessage] = useState("");
		const [recordToDelete, setRecordToDelete] = useState<string>("");

		useEffect(() => {
			if (subModRecordToEdit) {
				setIsOpen(true);
			} else {
				setIsOpen(false);
			}
		}, [subModRecordToEdit]);

		if (!subModule) {
			return null;
		}

		const addRecordToSubModule = () => {
			setIsOpen(true);
		};

		const cancelRecord = () => {
			setSubModRecordToEdit(undefined);
			setIsOpen(false);
		};

		const editRecord = (record: SubModuleRecord) => {
			setSubModRecordToEdit(record);
		};

		const deleteRecord = (recordId: string) => {
			dispatch(removeSubModuleRecord(recordId, subModule.id));
		};

		const removeRecord = (recordId: string) => {
			setRecordToDelete(recordId);
			setDeleteMessage(
				t("display:labelDeleteSubModuleAreYouSure", {
					record: subModule.singleRecordName
						? subModule.singleRecordName
						: subModule.name,
				}),
			);
			setDeleteConfirmOpen(true);
		};

		const saveSubModuleRecord = (record: SubModuleRecord) => {
			record.parentGuid = parentGuid;
			if (subModRecordToEdit) {
				dispatch(editSubModuleRecord(subModRecordToEdit.localId, record));
			} else {
				dispatch(createSubModuleRecord(subModule.id, record));
				if (isIQ) {
					dispatch(
						updateFieldProperty(
							parentId as number,
							[],
							UpdatableFieldProperty.actionValidationMessage,
						),
					);
				}
			}

			setSubModRecordToEdit(undefined);
			setIsOpen(false);
		};

		return (
			<>
				{subModule.type === SubmoduleType.PPE ? (
					<PPESubmodule
						addRecord={addRecordToSubModule}
						edit={editRecord}
						emptyListMessage={subModule.emptyListMessage}
						hasValidationErrors={hasValidationErrors}
						records={subModuleRecords}
						remove={removeRecord}
						submodule={subModule}
						validationErrors={validationErrors}
					/>
				) : (
					<SubmodulePresentation
						addRecord={addRecordToSubModule}
						allowActions={subModule.allowActions}
						allowAttachments={subModule.allowAttachments}
						edit={editRecord}
						emptyListMessage={subModule.emptyListMessage}
						hasValidationErrors={hasValidationErrors}
						headers={subModule.headers}
						hideHeader={subModule.hideHeader || isIQ || isInSubmodule}
						isIQ={isIQ}
						isInSubmodule={isInSubmodule}
						isMandatory={isMandatory}
						name={isIQ || isInSubmodule ? t("display:labelActions") : subModule.name}
						records={subModuleRecords}
						ref={ref}
						remove={removeRecord}
						singleRecordName={subModule.singleRecordName}
						validationErrors={validationErrors}
					/>
				)}
				<SubModuleDialog
					cancel={cancelRecord}
					isInSubmodule={isInSubmodule}
					isOpen={isOpen}
					parentId={parentId}
					saveRecord={saveSubModuleRecord}
					subModuleId={subModuleId}
					subModuleRecordId={subModRecordToEdit && subModRecordToEdit.localId}
				/>
				<ConfirmationModal
					onCancel={() => {
						setDeleteConfirmOpen(false);
						setRecordToDelete("");
					}}
					onConfirm={() => {
						setDeleteConfirmOpen(false);
						deleteRecord(recordToDelete);
						setRecordToDelete("");
					}}
					show={deleteConfirmOpen}
					text={deleteMessage}
				/>
			</>
		);
	},
);

SubModuleContainer.displayName = "SubModuleContainer";
