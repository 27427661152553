import { useCallback, useRef } from "react";
import { MapContainer, TileLayer, useMap, useMapEvents } from "react-leaflet";
import type { MouseEvent } from "react";
import "./MinimapControl.scss";

interface MinimapControlProps {
	label?: string;
	onClick?: () => void;
	tileLayerUrl: string;
}

const MINIMAP_SIZE = [104, 64];

export const MinimapControl = ({ label, onClick, tileLayerUrl }: MinimapControlProps) => {
	const parentMap = useMap();
	const mapZoom = parentMap.getZoom();
	const minmapRef = useRef<L.Map>(null);
	const controlRef = useRef<HTMLDivElement>(null);

	const calculateMinimapCenterLatLng = useCallback(() => {
		const parentMapSize = parentMap.getSize();
		const x = 10 + MINIMAP_SIZE[0] / 2;
		const y = parentMapSize.y - MINIMAP_SIZE[1] / 2 - 10;
		return parentMap.containerPointToLatLng([x, y]);
	}, [parentMap]);

	const updateMinimap = () => {
		minmapRef.current?.setView(calculateMinimapCenterLatLng(), parentMap.getZoom(), {
			animate: false,
		});
	};

	useMapEvents({
		move: () => {
			updateMinimap();
		},
		zoom: () => {
			updateMinimap();
		},
	});

	const handleClick = (event: MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		onClick?.();
	};

	return (
		<div className="leaflet-bottom leaflet-left">
			<div
				className="leaflet-control leaflet-bar"
				onClick={handleClick}
				onKeyDown={(event) => {
					if (event.key === "Enter" || event.key === " ") {
						onClick?.();
					}
				}}
				ref={controlRef}
				role="button"
				tabIndex={0}
			>
				<MapContainer
					attributionControl={false}
					center={calculateMinimapCenterLatLng()}
					doubleClickZoom={false}
					dragging={false}
					ref={minmapRef}
					scrollWheelZoom={false}
					style={{ height: MINIMAP_SIZE[1], width: MINIMAP_SIZE[0] }}
					tap={false}
					zoom={mapZoom}
					zoomControl={false}
				>
					<TileLayer url={tileLayerUrl} />
				</MapContainer>
				{label ? <div className="minimap__label">{label}</div> : null}
			</div>
		</div>
	);
};
