import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DefaultTrainingService } from "../../services/training";
import { CancelablePromise } from "../../services/utilities/CancelablePromise";
import type { State } from "../../state";
import type { Portal } from "../../models/portal";

export const useGetTrainingCount = () => {
	const { customerKey, portalKey } = useParams<{
		formsTab: string;
		portalKey: string;
		customerKey: string;
	}>();

	const service = useMemo(
		() =>
			new DefaultTrainingService({
				subdomain: "Learn",
			}),
		[],
	);

	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	const { data: trainingCount } = useQuery({
		queryKey: ["trainingRecordsCount"],

		queryFn: () => {
			if (!portal?.showLearnTraining) {
				return null;
			}

			const cancelTokenSource = axios.CancelToken.source();

			const getTrainingRecords = new CancelablePromise<number>((resolve, reject) =>
				service
					.getTrainingRecordsCount()
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);

			getTrainingRecords.cancel = () => {
				cancelTokenSource.cancel();
			};

			return getTrainingRecords;
		},
	});

	return trainingCount ?? 0;
};
