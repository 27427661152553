import { Controller, useFormContext } from "react-hook-form";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import { usePreventBrowserReload } from "../../../../../../utilities/UsePreventBrowserReload";
import type { ActionComment } from "../../../../../../../models/action";
import "./_action-comments-view-edit.scss";
import { usePortalPath } from "../../../../../../../helpers/UsePortalPath";

interface ActionCommentsViewEditProps {
	comment: ActionComment;
}

export const ActionCommentsViewEdit = ({ comment }: ActionCommentsViewEditProps) => {
	const { content, deletedBy, id, status } = comment;
	const portalPath = usePortalPath();
	const history = useHistory();
	const isEditCommentRoute = !!useRouteMatch(
		"/:customerKey/p/:portalKey/actions/:action/:actionId/comments/edit/:commentId",
	);
	const { control, formState } = useFormContext();
	const { action, actionId } = useParams<{
		action: string;
		actionId: string;
	}>();

	if (isEditCommentRoute && !comment.userCanEdit) {
		history.push(`${portalPath}actions/${action}/${actionId}/comments/${comment.id}`);
	}

	const { t } = useTranslation();

	usePreventBrowserReload(formState.isDirty);

	return (
		<div className="action-comments-view-edit">
			{isEditCommentRoute ? (
				<>
					<Controller
						control={control}
						defaultValue={content}
						name="NoteComment"
						render={({ field: { ...props } }) => (
							<TextareaAutosize
								className="she-components-text-input"
								id={id}
								maxLength={5000}
								{...props}
							/>
						)}
						rules={{
							maxLength: 5000,
						}}
					/>

					<div className="she-control-hint">
						{t("hintTextFieldLength", {
							minLength: 0,
							maxLength: 5000,
						})}
					</div>
				</>
			) : (
				<p className="action-comments-last-view__description">
					{status.key === "Deleted"
						? t("myTasks.labelCommentDeletedBy", { name: deletedBy })
						: content}
				</p>
			)}
		</div>
	);
};

ActionCommentsViewEdit.displayName = "ActionCommentsViewEdit";
