import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { saveInitialSetup, updateTemplateSyncFlag } from "../../../../state";
import { PortalAuthenticationMode } from "../../../../models/portal";
import { useAppDispatch } from "../../../../state/hooks/useAppDispatch";
import { InitialSetupPresentation } from "./InitialSetup.presentation";
import type { State } from "../../../../state";
import type { Portal, QuestionnaireStub } from "../../../../models/portal";

interface Props {
	customerKey: string;
	portalKey: string;
}

export const InitialSetupContainer = ({ customerKey, portalKey }: Props) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);
	const [show, setShow] = useState(true);
	const [values, setValues] = useState(
		portal &&
			portal.questionnaireStubs
				.filter((s) => s.makeAvailableOffline === true)
				.map((s) => Number.parseInt(s.key)),
	);
	const showPerfWarning =
		(portal && portal.questionnaireStubs && portal.questionnaireStubs.length > 10) || false;

	const saveSettings = () => {
		if (portal && values) {
			const stubs = portal.questionnaireStubs;
			stubs.forEach((s) => {
				s.makeAvailableOffline = values.includes(Number.parseInt(s.key));
			});
			dispatch(saveInitialSetup(stubs, portal.key));

			if (values.length > 0) {
				dispatch(updateTemplateSyncFlag(portal.key, true));
			}
		}

		setShow(false);
	};

	const questionnairesToShow = useMemo(() => {
		let questionnairesToShow: QuestionnaireStub[] = [];
		if (portal) {
			questionnairesToShow = portal.questionnaireStubs.filter((qs) => {
				return !(
					portal.authenticationMode === PortalAuthenticationMode.Public &&
					qs.authenticationRequired
				);
			});
		}
		return questionnairesToShow;
	}, [portal]);

	const onCancel = () => {
		portal && dispatch(saveInitialSetup(portal.questionnaireStubs, portal.key));
		setShow(false);
	};

	const onChange = (key: number) => {
		const selectedValues = values ? Array.from(values) : [];

		if (selectedValues.includes(key)) {
			const index = selectedValues.indexOf(key);
			if (index > -1) {
				selectedValues.splice(index, 1);
			}
		} else {
			selectedValues.push(key);
		}

		setValues(selectedValues);
	};

	return (
		<InitialSetupPresentation
			cancelText={t("global:close")}
			changeLater={t("display:labelSetupChange")}
			confirmText={t("global:confirm")}
			modalTitle={t("display:labelSetupTitle")}
			onCancel={onCancel}
			onChange={onChange}
			perfWarning={t("display:labelSetupPerfWarning")}
			save={saveSettings}
			selectedValues={values || []}
			setupQuestion={t("display:labelSetupQuestion")}
			show={show}
			showPerfWarning={showPerfWarning}
			stubs={questionnairesToShow}
		/>
	);
};
