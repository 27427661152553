import { useState, useEffect, useMemo } from "react";
import { CommentBoxValidator } from "../../../../validators/commentBoxValidator/commentBoxValidator";
import { updateComments } from "../../../../state/components/questionnaire";
import { useAppDispatch } from "../../../../state/hooks/useAppDispatch";
import { CommentBoxPresentation } from "./CommentBox.presentation";
import type { Field } from "../../../../models/fields/Field";
import type { NotApplicableComments } from "../../../../models/questionnaire";

interface Props {
	field: Field;
	isVisible: boolean;
	isMandatory: boolean;
	notApplicableComments?: NotApplicableComments;
}

export const CommentBoxContainer = ({
	field,
	isMandatory,
	isVisible,
	notApplicableComments,
}: Props) => {
	const [comment, setComment] = useState(field.comments.value);
	const dispatch = useAppDispatch();
	const validator = useMemo(
		() => new CommentBoxValidator(field, notApplicableComments),
		[field, notApplicableComments],
	);
	const [localIsVisible, setLocalIsVisible] = useState(isVisible);
	const [isInvalid, setIsInvalid] = useState(false);
	const [autoFocus, setAutoFocus] = useState(false);

	useEffect(() => {
		setLocalIsVisible(isVisible);
	}, [isVisible, setLocalIsVisible]);

	useEffect(() => {
		if (!isMandatory) {
			setIsInvalid(false);
		}
	}, [isMandatory, setIsInvalid]);

	useEffect(() => {
		setComment(field.comments.value);
		setIsInvalid(!validator.isFieldValid(field.comments.value));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.comments.value, dispatch, field.id, field.validationMessage.length]);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(event.target.value);
	};

	const onBlur = () => {
		setIsInvalid(!validator.isFieldValid(comment));
		dispatch(updateComments(field.id, comment || ""));
	};

	const onAddComment = () => {
		setAutoFocus(true);
		setLocalIsVisible(true);
	};

	return (
		<CommentBoxPresentation
			// eslint-disable-next-line jsx-a11y/no-autofocus
			autoFocus={autoFocus}
			comments={comment || ""}
			fieldGuid={field.guid}
			isEnabled={field.comments.isEnabled}
			isInvalid={isInvalid}
			isIq={field.isIq}
			isMandatory={isMandatory}
			isVisible={localIsVisible}
			onAddComment={onAddComment}
			onBlur={onBlur}
			onChange={onChange}
		/>
	);
};
