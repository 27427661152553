import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { NotApplicableComments } from "../../../../models/questionnaire";
import { markSectionNA } from "../../../../state/components/questionnaire";
import { ButtonTriggeredModal, ModalType } from "../../modals/ButtonTriggeredModal";
import { useAppDispatch } from "../../../../state/hooks/useAppDispatch";
import { TextBox } from "../TextBox";
import type { State } from "../../../../state";

interface Props {
	groupId: string;
}

const maxCommentLength = 10000;

export const MarkSectionNA = ({ groupId }: Props) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [comment, setComment] = useState("");
	const [validationMessage, setValidationMessage] = useState("");
	const className = classnames("she-components-control", {
		"she-components-validation-border-left": validationMessage,
	});

	const notApplicableComments = useSelector<State, NotApplicableComments | undefined>(
		(s) => s.questionnaire.questionnaire && s.questionnaire.questionnaire.notApplicableComments,
	);

	const handleMarkSectionNA = useCallback(() => {
		dispatch(markSectionNA(groupId, comment));
		setComment("");
	}, [dispatch, groupId, comment]);

	const handleComment = (comment: string) => {
		setComment(comment);
		if (comment.length > maxCommentLength) {
			setValidationMessage(
				t("validation:maxCommentLengthMessage", { maxLength: maxCommentLength }),
			);
		} else {
			setValidationMessage("");
		}
	};

	return (
		<ButtonTriggeredModal
			buttonLabel={t("display:buttonMarkAllNA")}
			cancelText={t("global:cancel")}
			modalType={
				notApplicableComments === NotApplicableComments.Hidden
					? ModalType.Confirmation
					: ModalType.Form
			}
			okText={t("global:ok")}
			onCancel={() => {
				setComment("");
			}}
			onOk={handleMarkSectionNA}
		>
			<div className={className}>
				<div>{t("display:labelInfoMarkNA")}</div>
				{(notApplicableComments === NotApplicableComments.Required ||
					notApplicableComments === NotApplicableComments.Visible) && (
					<>
						<div>{t("display:labelCommentNAHint")}</div>
						{notApplicableComments === NotApplicableComments.Required && (
							<div>{t("display:labelCommentNARequired")}</div>
						)}
						<label
							className="she-components-control-subtitle"
							htmlFor="she-na-modal-comment"
						>
							{t("display:labelCommentNA")}
						</label>
						{validationMessage && (
							<div className="she-components-validation-message">
								{validationMessage}
							</div>
						)}
						<TextBox
							id="she-na-modal-comment"
							invalid={!!validationMessage}
							large
							maxLength={maxCommentLength}
							onChange={handleComment}
							value={comment}
						/>
					</>
				)}
			</div>
		</ButtonTriggeredModal>
	);
};
